export enum InvitationTypeEnum {
	InviteExisting = "InviteExisting",
	InviteNew = "InviteNew"
}

export enum InvitationStatusEnum {
	AccountCreated = "AccountCreated",
	VerificationFailed = "VerificationFailed",
	Started = "Started",
	Completed = "Completed",
	ProvisioningCompleted = "ProvisioningCompleted",
	AwaitingVerification = "AwaitingVerification",
	InvitationSent = "InvitationSent",
	Abandoned = "Abandoned",
	Cancelled = "Cancelled",
	InvitationSentAndJobCompleted = "InvitationSentAndJobCompleted",
	OrganizationCreated = "OrganizationCreated",
	AccessControlUpdated = "AccessControlUpdated",

	Pending = "Pending", // Onboarding job has been created, and is pending/not yet started 
	AspNetUserCreated = "AspNetUserCreated", // A new aspnet user was created
	MatrixUserCreated = "MatrixUserCreated", // A new matrix user was created
	DehydrationDeviceCreated = "DehydrationDeviceCreated", // Dehydration deviceC created for matrix user was successful
	RoomCreated = "RoomCreated", // A new DM room was created
	RoomJoined = "RoomJoined", // DM room was joined
	VmxUserCreated = "VmxUserCreated", // A new vmx user was created
	ClientCreated = "ClientCreated", // A new client was created
	PersonAddedToClient = "PersonAddedToClient", // A person was added to the new client
	EmailPushedToSynapse = "EmailPushedToSynapse", // Email address was pushed to synapse
	RegistrationEmailSent = "RegistrationEmailSent", // Registration email was sent to new user
	UserAlreadyExists = "UserAlreadyExists", //  The job has been completed because the user already exists
	Failed = "Failed"
}

export enum linkRegistrationEnum {
	NewClientInvite = "NewClientInvite"
}

export enum LinkOnboardingKind {
	Person = "Person",
	Company = "Company"
}

export enum RoleEnum {
	Client = "Client",
	Admin = "Admin",
	//Employee = "Employee"
}

export const roleMap: Record<string,string> = {
	"Employee" : "Administrator",
	"Admin" : "Administrator",
	"Client": "Standard"
}

export enum RouteTypes {
	None = "$NONE"
}

export enum UserTypeEnum {
	Standard = "Standard",
	AdHoc = "AdHoc"
}

export type InvitationType = InvitationTypeEnum.InviteNew | InvitationTypeEnum.InviteExisting

export type InvitationStatusExisting = InvitationStatusEnum.Started | InvitationStatusEnum.InvitationSentAndJobCompleted

export type InvitationStatusNew = InvitationStatusEnum.Started | InvitationStatusEnum.Completed | InvitationStatusEnum.ProvisioningCompleted | InvitationStatusEnum.InvitationSent | InvitationStatusEnum.AwaitingVerification | InvitationStatusEnum.Abandoned | InvitationStatusEnum.Cancelled

export enum CustomerEnum {
	//this module
	Customer = "Customer",

	//permissions
	DisplayPrimaryContact = "customer-primary-contact:display",
	ViewPrimaryContact = "customer-primary-contact:view",
	ManagePrimaryContact = "customer-primary-contact:manage",

	ListPersons = "customer-person:list",
	RemovePersons = "customer-person:remove",
	ReassignPersonsToCompany = "customer-person:reassign-to-company",
	ReassignPersonsToExisting = "customer-person:reassign-to-existing",
	ReassignPersonsToNew = "customer-person:reassign-to-new",

	// Client specific
	ClientDisplayPrimaryContact = "client-primary-contact:display",
	ClientViewPrimaryContact = "client-primary-contact:view",
	ClientManagePrimaryContact = "client-primary-contact:manage",

	ClientListPersons = "client-person:list",
	ClientRemovePersons = "client-person:remove",
	ClientReassignPersonsToCompany = "client-person:reassign-to-company",
	ClientReassignPersonsToExisting = "client-person:reassign-to-existing",
	ClientReassignPersonsToNew = "client-person:reassign-to-new",

	// modules
	CustomerSettings = "CustomerSettings",

	// Tabs
	CustomerPersons = "CustomerPersons",
	CustomerInvitations = "CustomerInvitations",
	CustomerClients = "CustomerClients"
}

export enum ClientEnum {
	ClientOrganization = "ClientOrganization",

	//permissions
	ClientDisplayPrimaryContact = "client-primary-contact:display",
	ClientViewPrimaryContact = "client-primary-contact:view",
	ClientManagePrimaryContact = "client-primary-contact:manage",

	ClientListPersons = "client-person:list",
	ClientRemovePersons = "client-person:remove",
	ClientReassignPersonsToCompany = "client-person:reassign-to-company",
	ClientReassignPersonsToExisting = "client-person:reassign-to-existing",
	ClientReassignPersonsToNew = "client-person:reassign-to-new"
}

export enum InvitationEnum {
	//this module
	Invitation = "Invitation",

	//permissions
	CorrectInvitation = "invitation:correct",
	ListInvitation = "invitation:list"

	// modules
	
}

export enum LinkOnboardingEnum {
	LinkOnboarding = "LinkOnboarding",

	OnboardingOwnerAssign = "link-ownership:delegate",
	OnboardingOwner = "link-ownership:selfonly",
}

export enum NavSubItems {
    AccountRemove = "AccountRemove",
    AccountOverview = "AccountOverview"
}

////////////////////
// Manage Account //
////////////////////

	// Results.vue
export type TableMode = {
	remove: boolean
	view: boolean
}
	//RadioSearch.vue
export enum RadioSearchValues {
	company = "company",
	email = "email",
	userId = "userId"
}

////////////////////
///// ACL Enums ////
////////////////////

export enum AnyDomain {
    anyInstance = "tob-any-instance"
}

export enum ModuleName {
    onboarding = "Onboarding"
}

export enum OnboardingUiEnum {
    onboardingOwnership = "onboarding-ownership:delegate",
    createTenant= "tenant:create"
}

export enum OnboardingRoles {
    onboardingManager = "Onboarding-Manager",
    tenantOnboarder = "Onboarding-TenantOnboarder"
}

////////////////////////////
///// TENANT ONBOARDING ////
////////////////////////////

export enum CreateTenantErrorTypes {
    companyName = "NewTenantOnboarding.CompanyName",
    businessRegistryId = "NewTenantOnboarding.BusinessRegistryId",
    email = "NewTenantOnboarding.Email",
    phoneNumber = "NewTenantOnboarding.PhoneNumber"
}