import { AxiosError, AxiosRequestConfig } from "axios"
import axios from "axios"
import router from "@/router"
import store, { Store } from "@/store"
// TBD: import some notifier/modal/sweetalert or similar

// Request interceptor
// This is defined in provisioningClient.ts
/*
axios.interceptors.request.use(request => {
	const token = (store as Store).state.oidcStore.access_token
	if (token) {
		request.headers.common['Authorization'] = `Bearer ${token}`
	}
	return request
})
*/

// Global Error Response Logger interceptor
axios.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		if (!error.response) {
			console.error("SERVER ERROR! No response")
			return
		}

		const { status } = error.response
		const { data } = error.response
		const { code } = error

		if (status === 404) {
			console.error("NOT FOUND! Code: " + code, data)
		}

		if (status === 429) {
			console.error("TOO MANY REQUESTS! Code: " + code, data)
		}

		if (status >= 500) {
			console.error("SERVER ERROR! Code: " + code, data)
		}

		if (status === 401) {
			console.error("NOT AUTHENTICATED! Code: " + code, data)
			//router.push({ name: "Logout" })
		}

		if (status === 400) {
			console.error("BAD REQUEST! Code: " + code, data)
		}

		return Promise.reject(error)
	}
)
