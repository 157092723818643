import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import no from "vuetify/src/locale/no";
import { UserVuetifyPreset } from "vuetify/types"
import { FontAwesomeIcons } from "./fontawesome"
Vue.use(Vuetify);

const vuetifyOptions: UserVuetifyPreset = {
    /*
	lang: {
        locales: { no },
        current: 'no',
    },
	*/
    theme: {
        themes: {
            light: {
				lighttouch: "#f5f5f5",
                primary: "#73bf45",
                secondary: "#dedede",
                accent: "#dedede",
                error: "#FF5252",
                info: "#2196F3",
                highlight: "#6698c8",
                success: "#73bf45",
                warning: "#FFC107",
				dark: "#231f20"
            }
        }
    },
    customProperties: true,
    icons: FontAwesomeIcons
};

export default new Vuetify(vuetifyOptions);