import { middlewareContext } from "./types"
import { guardColor } from "./index"

export default async ({ to, from, next, store, router }: middlewareContext): Promise<void> => {
	if (store.state.config.loggerMiddleware) console.log("%c[OIDC]", guardColor.info)

	const hasAccess = await store.dispatch("oidcStore/oidcCheckAccess", to);
	if (!hasAccess) {
		if (store.state.config.loggerMiddleware) console.log(`%c[OIDC - NOT AUTHENTICATED]`, guardColor.error)
	} else {
		next()
	}
}
