import { middlewareContext } from "./types"
import { guardColor } from "./index"
const globalPaths: string[] = [
	"/context",
	"/profile", 
	"/oidc-callback",
	"/oidc-callback-error",
	"/oidc-silent-renew",
	"/logout",
]

export default async ({ to, from, next, store, router }: middlewareContext): Promise<void> => {
	if (store.state.config.loggerMiddleware) console.log("%c[NAV]", guardColor.info)

	const paths = store.state.nav.allowedPaths.length !== 0 ? globalPaths.concat(store.state.nav.allowedPaths) : globalPaths;

	if (!paths.includes(to.path)) {
		if (store.state.config.loggerMiddleware)
			console.log(`%c[NAV - ROUTE NOT ALLOWED]`, guardColor.error)
		// eslint-disable-next-line
		router.push({ path: from.path }).catch(() => {})
		return;
	} else {
		next()
	}
}