import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex"
import { Namespaced, NamespacedState } from "@/types/functionTypes"
import { ApiClient } from "@/api/apiClient"
import store, { Store } from "@/store"
import { CreateTenantPayload } from "@/types/requests"
import { 
	ClientInvitationResponse, ClientInvitationInfo, CreateClientInvitationRequest, ClientInvitation, 
	ClientInvitationInfoResponse, ClientInvitationRegistrationsResponse, ChangeActiveRequest, ChangeManualApprovalRequest,
	ClientInvitationRegistration, 
	MemberInfo,
	Fetched,
    AclResponse,
    AnyDomainPermissions
} from "@/types/responses"
import { FindPersonAccountRequest } from "@/types/requests"
import { DomainsResponse, Domain, Person, Client, CompanyInfo, CustomerInfoResponse, PersonsResponse, CustomerClientsResponse, ClientPersons, ClientInfoResponse, ReassignPersonRequest, ReassignClientPersonRequest, GetAccountDetails, FindPersonAccountResponse, AccountItem } from "@/types/responses"
import {v4 as uuidv4} from 'uuid';
import { ModuleName, OnboardingRoles, OnboardingUiEnum } from "@/types/enums"
import axios, { AxiosError } from "axios"

// state
export type State = {
	fetched: Fetched
	domains: DomainsResponse["results"]
	persons: PersonsResponse["results"]
	assignableLinkOwners: Array<MemberInfo>
	customerClients: CustomerClientsResponse["results"]
    associatedClients: CustomerClientsResponse["results"]
	clientInvitations: ClientInvitationResponse["results"]
	clientPersons: Array<ClientPersons>
	selectedPerson: null | Person
	selectedCustomer: null | Domain
	selectedCustomerInfo: null | CustomerInfoResponse
	selectedClient: null | Client
	selectedClientInfo: null | ClientInfoResponse
	selectedClientInvitation: null | ClientInvitation
	selectedClientInvitationInfo: null | ClientInvitationInfoResponse
	selectedClientInvitationRegistrations: ClientInvitationRegistrationsResponse["results"]
	selectedRegistration: null | ClientInvitationRegistration
	findPersonAccounts: null | FindPersonAccountResponse["results"]
	accountSummary: null | GetAccountDetails
	selectedAccount: null | AccountItem
    createTenantFormData: CreateTenantPayload
    anyDomainAcl: null | AclResponse
    anyDomainPermissions: AnyDomainPermissions
}

export const state: State = {
	fetched: {
		domains: false
	},
	domains: [],
	persons: [],
	assignableLinkOwners: [],
	customerClients: [],
    associatedClients: [],
	clientInvitations: [],
	clientPersons: [],
	selectedPerson: null,
	selectedCustomer: null,
	selectedCustomerInfo: null,
	selectedClient: null,
	selectedClientInfo: null,
	selectedClientInvitation: null,
	selectedClientInvitationInfo: null,
	selectedClientInvitationRegistrations: [],
	selectedRegistration: null,
	findPersonAccounts: [],
	accountSummary: null,
	selectedAccount: null,
    createTenantFormData: {
        tenantOnboardingId: "",
        businessRegistryId: "",
        companyName: "",
        email: "",
        invitingPersonDisplayName: "",
        invitingPersonId: "",
        phoneNumber: "",
        tenantId: ""
    },
    anyDomainAcl: null,
    anyDomainPermissions: {
        isTenantOnboarder: false
    }
}

// mutations
enum MutationTypes {
	SET_FETCHED = "SET_FETCHED",
	SET_DOMAINS = "SET_DOMAINS",
	SET_PERSONS = "SET_PERSONS",
	SET_PERSON = "SET_PERSON",
	SET_ASSIGNABLELINKOWNERS = "SET_ASSIGNABLELINKOWNERS",
	SET_CUSTOMERCLIENTS = "SET_CUSTOMERCLIENTS",
	SET_CLIENTINVITATONS = "SET_CLIENTINVITATONS",
	SET_CLIENTPERSONS = "SET_CLIENTPERSONS",
    SET_ASSOCIATEDCLIENTS = "SET_ASSOCIATEDCLIENTS",
	SET_CUSTOMER = "SET_CUSTOMER",
	SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO",
	SET_CLIENT = "SET_CLIENT",
	SET_CLIENT_INFO = "SET_CLIENT_INFO",
	SET_CLIENT_INVITATION = "SET_CLIENT_INVITATION",
	SET_CLIENT_INVITATION_INFO = "SET_CLIENT_INVITATION_INFO",
	SET_CLIENT_INVITATION_REGISTRATIONS = "SET_CLIENT_INVITATION_REGISTRATIONS",
	SET_REGISTRATION = "SET_SELECTED_REGISTRATION",
	RESET_CONTEXT = "RESET_CONTEXT",
	RESET_PERSONS = "RESET_PERSONS",
	RESET_CUSTOMERCLIENTS = "RESET_CUSTOMERCLIENTS",
    RESET_ASSOCIATEDCLIENTS = "RESET_ASSOCIATEDCLIENTS",
	RESET_CLIENTPERSONS = "RESET_CLIENTPERSONS",
	RESET_CUSTOMER_INFO = "RESET_CUSTOMER_INFO",
	RESET_CLIENT_INFO = "RESET_CLIENT_INFO",
	RESET_CLIENT_INVITATIONS = "RESET_CUSTOMER_INFO",
	RESET_CLIENT_INVITATION_INFO = "RESET_CLIENT_INVITATION_INFO",
	RESET_CLIENT_INVITATION_REGISTRATIONS = "RESET_CLIENT_INVITATION_REGISTRATIONS",
	RESET_REGISTRATION = "RESET_SELECTED_REGISTRATION",
	SET_FIND_ACCOUNT_RESULTS = "SET_FIND_ACCOUNT_RESULTS",
	SET_SELECTED_ACCOUNT_DETAILS = "SET_SELECTED_ACCOUNT_DETAILS",
	RESET_FIND_ACCOUNT_RESULTS = "RESET_FIND_ACCOUNT_RESULTS",
	RESET_SELECTED_ACCOUNT_DETAILS = "RESET_SELECTED_ACCOUNT_DETAILS",
	SET_ACCOUNT_RESULTS = "SET_ACCOUNT_RESULTS",
	SET_SELECTED_ACCOUNT = "SET_SELECT_SELECTED_ACCOUNT",
    SET_CREATE_TENANT_FORM_DATA = "SET_CREATE_TENANT_FORM_DATA",
    RESET_CREATE_TENANT_FORM_DATA = "RESET_CREATE_TENANT_FORM_DATA",
    SET_ANY_DOMAIN_ACL = "SET_ANY_DOMAIN_ACL",
    SET_ANY_DOMAIN_PERMISSIONS = "SET_ANY_DOMAIN_PERMISSIONS"
}
export type Mutations<S = State> = {
	[MutationTypes.SET_FETCHED](state: S, fetched: Fetched): void
	[MutationTypes.SET_DOMAINS](state: S, domains: DomainsResponse["results"]): void
	[MutationTypes.SET_PERSONS](state: S, persons: PersonsResponse["results"]): void
	[MutationTypes.SET_PERSON](state: S, person: Person | null): void
	[MutationTypes.SET_ASSIGNABLELINKOWNERS](state: S, linkOwners: Array<MemberInfo>): void
	[MutationTypes.SET_CUSTOMERCLIENTS](state: S, customerClients: CustomerClientsResponse["results"]): void
    [MutationTypes.SET_ASSOCIATEDCLIENTS](state: S, associatedClients: CustomerClientsResponse["results"]): void
	[MutationTypes.SET_CLIENTINVITATONS](state: S, clientInvitations: Array<ClientInvitationInfo>): void
	[MutationTypes.SET_CLIENTPERSONS](state: S, clientPersons: Array<ClientPersons>): void
	[MutationTypes.SET_CUSTOMER](state: S, customer: Domain | null): void
	[MutationTypes.SET_CUSTOMER_INFO](state: S, info: CustomerInfoResponse | null): void
	[MutationTypes.SET_CLIENT](state: S, client: Client | null): void
	[MutationTypes.SET_CLIENT_INFO](state: S, info: ClientInfoResponse | null): void
	[MutationTypes.SET_CLIENT_INVITATION](state: S, invitation: ClientInvitation | null): void
	[MutationTypes.SET_CLIENT_INVITATION_INFO](state: S, info: ClientInvitationInfoResponse | null): void
	[MutationTypes.SET_CLIENT_INVITATION_REGISTRATIONS](state: S, registrations: ClientInvitationRegistrationsResponse["results"]): void
	[MutationTypes.SET_REGISTRATION](state: S, registration: ClientInvitationRegistration | null): void
    [MutationTypes.SET_ANY_DOMAIN_ACL](state: S, anyDomainAcl: AclResponse | null): void
    [MutationTypes.SET_ANY_DOMAIN_PERMISSIONS](state: S, anyDomainPermissions: AnyDomainPermissions): void
	[MutationTypes.RESET_CONTEXT](state: S): void
	[MutationTypes.RESET_PERSONS](state: S): void
	[MutationTypes.RESET_CUSTOMERCLIENTS](state: S): void
    [MutationTypes.RESET_ASSOCIATEDCLIENTS](state: S): void
	[MutationTypes.RESET_CLIENTPERSONS](state: S): void
	[MutationTypes.RESET_CUSTOMER_INFO](state: S): void
	[MutationTypes.RESET_CLIENT_INFO](state: S): void
	[MutationTypes.RESET_CLIENT_INVITATIONS](state: S): void
	[MutationTypes.RESET_CLIENT_INVITATION_INFO](state: S): void
	[MutationTypes.RESET_CLIENT_INVITATION_REGISTRATIONS](state: S): void
	[MutationTypes.RESET_REGISTRATION](state: S): void
	[MutationTypes.SET_FIND_ACCOUNT_RESULTS](state: S, info: FindPersonAccountResponse["results"] | null): void
	[MutationTypes.SET_SELECTED_ACCOUNT_DETAILS](state: State, results: GetAccountDetails| null): void
	[MutationTypes.RESET_FIND_ACCOUNT_RESULTS](state: S): void
	[MutationTypes.RESET_SELECTED_ACCOUNT_DETAILS](state: S): void
	[MutationTypes.SET_ACCOUNT_RESULTS](state: S, results: FindPersonAccountResponse["results"]): void
	[MutationTypes.SET_SELECTED_ACCOUNT](state: S, account: AccountItem | null): void
    [MutationTypes.SET_CREATE_TENANT_FORM_DATA](state: S, createTenantFormData: CreateTenantPayload | null): void
    [MutationTypes.RESET_CREATE_TENANT_FORM_DATA](state: S): void
}
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_FETCHED](state: State, fetched: Fetched) {
		state.fetched = fetched
	},
	[MutationTypes.SET_DOMAINS](state: State, domains: DomainsResponse["results"]) {
		state.domains = domains
	},
	[MutationTypes.SET_PERSONS](state: State, persons: PersonsResponse["results"]) {
		state.persons = persons
	},
	[MutationTypes.SET_PERSON](state: State, person: Person | null) {
		state.selectedPerson = person
	},
	[MutationTypes.SET_ASSIGNABLELINKOWNERS](state: State, linkOwners: Array<MemberInfo>) {
		state.assignableLinkOwners = linkOwners
	},
	[MutationTypes.SET_CUSTOMERCLIENTS](state: State, customerClients: CustomerClientsResponse["results"]) {
		state.customerClients = customerClients
	},
	[MutationTypes.SET_ASSOCIATEDCLIENTS](state: State, associatedClients: CustomerClientsResponse["results"]) {
		state.associatedClients = associatedClients
	},
	[MutationTypes.SET_CLIENTINVITATONS](state: State, clientInvitations: Array<ClientInvitationInfo>) {
		state.clientInvitations = clientInvitations
	},
	[MutationTypes.SET_CLIENTPERSONS](state: State, clientPersons: Array<ClientPersons>) {
		state.clientPersons = clientPersons
	},
	[MutationTypes.SET_CUSTOMER](state: State, customer: Domain | null) {
		state.selectedCustomer = customer
	},
	[MutationTypes.SET_CUSTOMER_INFO](state: State, info: CustomerInfoResponse | null) {
		state.selectedCustomerInfo = info
	},
	[MutationTypes.SET_CLIENT](state: State, client: Client | null) {
		state.selectedClient = client
	},
	[MutationTypes.SET_CLIENT_INFO](state: State, info: ClientInfoResponse | null) {
		state.selectedClientInfo = info
	},
	[MutationTypes.SET_CLIENT_INVITATION](state: State, invitation: ClientInvitation | null) {
		state.selectedClientInvitation = invitation
	},
	[MutationTypes.SET_CLIENT_INVITATION_INFO](state: State, info: ClientInvitationInfoResponse | null) {
		state.selectedClientInvitationInfo = info
	},
	[MutationTypes.SET_CLIENT_INVITATION_REGISTRATIONS](state: State, registrations: ClientInvitationRegistrationsResponse["results"]) {
		state.selectedClientInvitationRegistrations = registrations
	},
	[MutationTypes.SET_REGISTRATION](state: State, registration: ClientInvitationRegistration | null) {
		state.selectedRegistration = registration
	},
    [MutationTypes.SET_ANY_DOMAIN_ACL](state: State, aclDomainAcl: AclResponse | null) {
		state.anyDomainAcl = aclDomainAcl
	},
    [MutationTypes.SET_ANY_DOMAIN_PERMISSIONS](state: State, anyDomainPermissions: AnyDomainPermissions){
        state.anyDomainPermissions = anyDomainPermissions
    },
	[MutationTypes.RESET_CONTEXT](state: State, u?: undefined) {
		state.fetched = {
			domains: false
		}
		state.domains = []
		state.persons = []
		state.customerClients = []
        state.associatedClients = []
		state.clientPersons = []
		state.clientInvitations = []
		state.selectedPerson = null
		state.selectedCustomer = null
		state.selectedCustomerInfo = null
		state.selectedClient = null
		state.selectedClientInfo = null
		state.selectedClientInvitation = null
		state.selectedClientInvitationInfo = null
		state.selectedClientInvitationRegistrations = []
		state.selectedRegistration = null
		state.findPersonAccounts = []
		state.accountSummary = null
        state.createTenantFormData = {} as CreateTenantPayload
        state.anyDomainAcl = null
        state.anyDomainPermissions = { isTenantOnboarder: false}
	},
	[MutationTypes.RESET_PERSONS](state: State, u?: undefined) {
		state.persons = []
	},
	[MutationTypes.RESET_CUSTOMERCLIENTS](state: State, u?: undefined) {
		state.customerClients = []
	},
	[MutationTypes.RESET_ASSOCIATEDCLIENTS](state: State, u?: undefined) {
		state.associatedClients = []
	},
	[MutationTypes.RESET_CLIENTPERSONS](state: State, u?: undefined) {
		state.selectedPerson = null
		state.clientPersons = []
	},
	[MutationTypes.RESET_CUSTOMER_INFO](state: State, u?: undefined) {
		state.selectedCustomerInfo = null
	},
	[MutationTypes.RESET_CLIENT_INFO](state: State, u?: undefined) {
		state.selectedClientInfo = null
	},
	[MutationTypes.RESET_CLIENT_INVITATIONS](state: State, u?: undefined) {
		state.selectedClientInvitation = null
		state.selectedClientInvitationInfo = null
		state.selectedClientInvitationRegistrations = []
		state.clientInvitations = []
		state.selectedRegistration = null
	},
	[MutationTypes.RESET_CLIENT_INVITATION_INFO](state: State, u?: undefined) {
		state.selectedClientInvitation = null
		state.selectedClientInvitationInfo = null
		state.selectedRegistration = null
	},
	[MutationTypes.RESET_CLIENT_INVITATION_REGISTRATIONS](state: State, u?: undefined) {
		state.selectedClientInvitationRegistrations = []
	},
	[MutationTypes.RESET_REGISTRATION](state: State, u?: undefined) {
		state.selectedRegistration = null
	},
	[MutationTypes.SET_FIND_ACCOUNT_RESULTS](state: State, results: FindPersonAccountResponse["results"] | null) {
		state.findPersonAccounts = results
	},
	[MutationTypes.SET_SELECTED_ACCOUNT_DETAILS](state: State, results: GetAccountDetails| null) {
		state.accountSummary = results
	},
	[MutationTypes.RESET_FIND_ACCOUNT_RESULTS](state: State, u?: undefined) {
		state.findPersonAccounts = []
	},
	[MutationTypes.RESET_SELECTED_ACCOUNT_DETAILS](state: State, u?: undefined) {
		state.accountSummary = null
	},
	[MutationTypes.SET_ACCOUNT_RESULTS](state: State, results: FindPersonAccountResponse["results"] | null) {
		state.findPersonAccounts = results
	},
	[MutationTypes.SET_SELECTED_ACCOUNT](state: State, account: AccountItem | null) {
		state.selectedAccount = account
	},
    [MutationTypes.SET_CREATE_TENANT_FORM_DATA](state: State, createTenantFormData: CreateTenantPayload) {
		state.createTenantFormData = createTenantFormData
	},
    [MutationTypes.RESET_CREATE_TENANT_FORM_DATA](state: State, u?: undefined) {
		state.createTenantFormData = {
            tenantOnboardingId: "",
            businessRegistryId: "",
            companyName: "",
            email: "",
            invitingPersonDisplayName: "",
            invitingPersonId: "",
            phoneNumber: "",
            tenantId: ""
        }
	},
}

// actions
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, "commit">
enum ActionTypes {
	setFetched = "setFetched",
	updateDomains = "updateDomains",
	loadDomains = "loadDomains",
	updatePersons = "updatePersons",
	setPerson = "setPerson",
	removeCustomerPerson = "removeCustomerPerson",
	removeClientPerson = "removeClientPerson",
	updateCustomerClients = "updateCustomerClients",
	updateClientInvitations = "updateClientInvitations",
	updatePersonClients = "updatePersonClients",
	updateClientPersons = "updateClientPersons",
	getClientInvitationInfo = "getClientInvitationInfo",
	getClientInvitationRegistrations = "getClientInvitationRegistrations",
	createClientInvitation = "createClientInvitation",
	changeActiveRequest = "changeActiveRequest",
	changeManualApprovalRequest = "changeManualApprovalRequest",
	rejectClientRegistration = "rejectClientRegistration",
	approveClientRegistration = "approveClientRegistration",
	setCustomer = "setCustomer",
	getCustomerInfo = "getCustomerInfo",
	getClientInfo = "getClientInfo",
	setClient = "setClient",
	setClientName = "setClientName",
	setClientDescription = "setClientDescription",
	setCompanyName = "setCompanyName",
	setCompanyDescription = "setCompanyDescription",
	setCompanyBusinessRegistryId = "setCompanyBusinessRegistryId",
	setClientInvitation = "setClientInvitation",
	setRegistration = "setRegistration",
	deleteRegistration = "deleteRegistration",
	setPrimaryContact = "setPrimaryContact",
	setClientPrimaryContact = "setClientPrimaryContact",
	reassignPerson = "reassignPerson",
	reassignClientPerson = "reassignClientPerson",
	resetContext = "resetContext",
	resetPersons = "resetPersons",
	resetCustomerClients = "resetCustomerClients",
    resetAssociatedClients = "resetAssociatedClients",
	resetClientPersons = "resetClientPersons",
	resetCustomerInfo = "resetCustomerInfo",
	resetClientInfo = "resetClientInfo",
	resetClientInvitations = "resetClientInvitations",
	resetClientInvitationInfo = "resetClientInvitationInfo",
	resetRegistration = "resetRegistration",
	findPersonAccount = "findPersonAccount",
	removeAccount = "removeAccount",
	accountSummary = "accountSummary",
	resetFindPersonAccount = "resetFindPersonAccount",
	resetAccountSummary = "resetAccountSummary",
	setSelectedAccount = "setSelectedAccount",
	updateAccountResults = "updateAccountResults",
	updateLinkOnboardingUsers = "updateLinkOnboardingUsers",
    createTenant = "createTenant",
    setCreateTenantFormData = "setCreateTenantFormData",
    resetCreateTenantFormData = "resetCreateTenantFormData",
    getAnyDomainAcl = "getAnyDomainAcl",
    setAnyDomainPermissions = "setAnyDomainPermissions"

}
export interface Actions {
	[ActionTypes.setFetched]({ commit }: AugmentedActionContext, fetched: Fetched): void
	[ActionTypes.updateDomains]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.loadDomains]({ commit }: AugmentedActionContext, domains: Domain[]): void
	[ActionTypes.updatePersons]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.updateLinkOnboardingUsers]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.setPerson]({ commit }: AugmentedActionContext, person: Person): void
	[ActionTypes.removeCustomerPerson]({ commit }: AugmentedActionContext, personId: string|null): Promise<void>
	[ActionTypes.removeClientPerson]({ commit }: AugmentedActionContext, personId: string|null): Promise<void>
	[ActionTypes.updateCustomerClients]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.updateClientInvitations]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.updatePersonClients]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.updateClientPersons]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.getClientInvitationInfo]({ commit }: AugmentedActionContext, info: ClientInvitationResponse): Promise<void>
	[ActionTypes.getClientInvitationRegistrations]({ commit }: AugmentedActionContext, registrations: ClientInvitationRegistrationsResponse): Promise<void>
	[ActionTypes.createClientInvitation]({ commit }: AugmentedActionContext, createClientInvitationRequest: CreateClientInvitationRequest): Promise<void>
	[ActionTypes.changeActiveRequest]({ commit }: AugmentedActionContext, changeActiveRequest: ChangeActiveRequest): Promise<void>
	[ActionTypes.changeManualApprovalRequest]({ commit }: AugmentedActionContext, changeManualApprovalRequest: ChangeManualApprovalRequest): Promise<void>
	[ActionTypes.rejectClientRegistration]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.approveClientRegistration]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.setCustomer]({ commit }: AugmentedActionContext, customer: Domain): void
	[ActionTypes.setClient]({ commit }: AugmentedActionContext, client: Client): void
	[ActionTypes.getCustomerInfo]({ commit }: AugmentedActionContext, info: CustomerInfoResponse): Promise<void>
	[ActionTypes.getClientInfo]({ commit }: AugmentedActionContext, info: ClientInfoResponse): Promise<void>
	[ActionTypes.setClientName]({ commit }: AugmentedActionContext, info: CompanyInfo): Promise<void>
	[ActionTypes.setClientDescription]({ commit }: AugmentedActionContext, info: CompanyInfo): Promise<void>
	[ActionTypes.setCompanyName]({ commit }: AugmentedActionContext, info: CompanyInfo): Promise<void>
	[ActionTypes.setCompanyDescription]({ commit }: AugmentedActionContext, info: CompanyInfo): Promise<void>
	[ActionTypes.setCompanyBusinessRegistryId]({ commit }: AugmentedActionContext, info: CompanyInfo): Promise<void>
	[ActionTypes.setClientInvitation]({ commit }: AugmentedActionContext, invitation: ClientInvitation): void
	[ActionTypes.setRegistration]({ commit }: AugmentedActionContext, registration: ClientInvitationRegistration): void
	[ActionTypes.deleteRegistration]({ commit }: AugmentedActionContext, registration: ClientInvitationRegistration): Promise<void>
	[ActionTypes.setPrimaryContact]({ commit }: AugmentedActionContext, primaryContactId: string|null): Promise<void>
	[ActionTypes.setClientPrimaryContact]({ commit }: AugmentedActionContext, primaryContactId: string|null): Promise<void>
	[ActionTypes.reassignPerson]({ commit }: AugmentedActionContext, reassignPersonRequest: ReassignPersonRequest): Promise<void>
	[ActionTypes.reassignClientPerson]({ commit }: AugmentedActionContext, reassignClientPersonRequest: ReassignClientPersonRequest): Promise<void>
	[ActionTypes.resetContext]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetPersons]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetCustomerClients]({ commit }: AugmentedActionContext, u?: undefined): void
    [ActionTypes.resetAssociatedClients]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetClientPersons]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetCustomerInfo]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetClientInfo]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetClientInvitations]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetClientInvitationInfo]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetRegistration]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.findPersonAccount]({ commit }: AugmentedActionContext, request: FindPersonAccountRequest): Promise<void>
	[ActionTypes.removeAccount]({ commit }: AugmentedActionContext, personId: string): void
	[ActionTypes.accountSummary]({ commit }: AugmentedActionContext, personId: string): Promise<void>
	[ActionTypes.resetFindPersonAccount]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.resetAccountSummary]({ commit }: AugmentedActionContext, u?: undefined): void
	[ActionTypes.updateAccountResults]({ commit }: AugmentedActionContext, results: Array<AccountItem>): Promise<void>
	[ActionTypes.setSelectedAccount]({ commit }: AugmentedActionContext, account: AccountItem): void
    [ActionTypes.createTenant]({ commit }: AugmentedActionContext, createTenant: CreateTenantPayload): Promise<void | string | AxiosError>
    [ActionTypes.setCreateTenantFormData]({ commit }: AugmentedActionContext, createTenantFormData: CreateTenantPayload): void
    [ActionTypes.resetCreateTenantFormData]({ commit }: AugmentedActionContext, u?: undefined): void
    [ActionTypes.getAnyDomainAcl]({ commit }: AugmentedActionContext, moduleName: string): void
    [ActionTypes.setAnyDomainPermissions]({ commit }: AugmentedActionContext, anyDomainPermissions: AnyDomainPermissions): void 
}

export const actions: ActionTree<State, State> & Actions = {
	async [ActionTypes.setFetched]({ commit }, fetched: Fetched) {
		commit(MutationTypes.SET_FETCHED, fetched)
	},
	async [ActionTypes.updateDomains]({ commit }) {
		try {
			const client = new ApiClient()
			const response: DomainsResponse = await client.getDomains()
			commit(MutationTypes.SET_DOMAINS, response.results)
		} catch (e) {
			console.warn("Could not resolve domains request. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_DOMAINS, [])
		}
	},
	async [ActionTypes.loadDomains]({ commit }, domains: Domain[]) {
		commit(MutationTypes.SET_DOMAINS, domains)
	},
	async [ActionTypes.updatePersons]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const response = await client.getPersons(customerId)
			commit(MutationTypes.SET_PERSONS, response.results)
		} catch (e) {
			console.warn("Could not get persons. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_PERSONS, [])
			throw(e)
		}
	},
	async [ActionTypes.updateLinkOnboardingUsers]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const response = await client.getLinkOnboardingUsers(customerId)
			const personIds = response.map(r => r.id);
			const resolvedPersons = await client.resolveCustomerPersons(customerId, personIds)
			commit(MutationTypes.SET_ASSIGNABLELINKOWNERS, resolvedPersons)
		} catch (e) {
			console.warn("Could not resolve persons. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_ASSIGNABLELINKOWNERS, [])
			throw(e)
		}
	},
	[ActionTypes.setPerson]({ commit }, person: Person | null) {
		if (person) commit(MutationTypes.SET_PERSON, person)
		else commit(MutationTypes.SET_PERSON, null)
	},
	async [ActionTypes.removeCustomerPerson]({ commit, dispatch }, personId: string | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			//const personId = (store as Store).state.context.selectedPerson?.id
			const response = await client.removeCustomerPerson(customerId, personId)
		} catch (e) {
			throw `Could not remove person from customer. Error: ${JSON.stringify(e)} `
		}
	},
	async [ActionTypes.removeClientPerson]({ commit, dispatch }, personId: string | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id

			//const personId = (store as Store).state.context.selectedPerson?.id
			const response = await client.removeClientPerson(customerId, clientId, personId)
		} catch (e) {
			throw `Could not remove person from customer. Error: ${JSON.stringify(e)} `
		}
	},
	async [ActionTypes.updateCustomerClients]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const response = await client.getCustomerClients(customerId)
			
			commit(MutationTypes.SET_CUSTOMERCLIENTS, response.results)
		} catch (e) {
			console.warn("Could not get customer clients. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CUSTOMERCLIENTS, [])
			throw(e)
		}
	},
    async [ActionTypes.updatePersonClients]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const personId = (store as Store).state.oidcStore.user ? (store as Store).state.oidcStore.user.sub : ""
			const response = await client.getPersonClients(customerId, personId)
            console.log("GetPersonClients: ", response)
			// HERE IS THE ERROR
			commit(MutationTypes.SET_ASSOCIATEDCLIENTS, response.results)
		} catch (e) {
			console.warn("Could not get associated clients. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_ASSOCIATEDCLIENTS, [])
			throw(e)
		}
	},
	async [ActionTypes.updateClientPersons]({ commit }){
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id
			const response = await client.getClientPersons(customerId, clientId)

			const currentClientPersons = (store as Store).state.context.clientPersons

			let exists = false;
			for(let i = 0; i < currentClientPersons.length; i++){
				if(currentClientPersons[i].clientId == clientId){
					currentClientPersons.splice(i, 1, {
						clientId: clientId,
						persons: response.results 
					});
					exists = true;
					break;
				}
			}
			if(!exists){
				currentClientPersons.push({
					clientId: clientId,
					persons: response.results 
				})
			}
			
			console.log(currentClientPersons)
			commit(MutationTypes.SET_CLIENTPERSONS, currentClientPersons)
		} catch (e) {
			console.warn("Could not get client persons. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_PERSONS, [])
			throw(e)
		}
	},
	async [ActionTypes.updateClientInvitations]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const response = await client.getClientInvitations(customerId)
			commit(MutationTypes.SET_CLIENTINVITATONS, response.results)
			console.log(response)
		} catch (e) {
			console.warn("Could not get client invitations. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CLIENTINVITATONS, [])
		}
	},
	async [ActionTypes.createClientInvitation]({ commit }, createClientInvitationRequest: CreateClientInvitationRequest) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			await client.createNewClientInvitation(
				customerId, 
				createClientInvitationRequest.name,
				createClientInvitationRequest.invitingPersonId,
				createClientInvitationRequest.manualApproval,
                createClientInvitationRequest.onboardingKind
			)

		} catch (e) {
			console.error("Could not create new client invitation. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.changeActiveRequest]({ commit }, changeActiveRequest: ChangeActiveRequest) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedClientInvitation?.id
			if(!clientInvitationId) console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation

			await client.changeClientInvitationActiveStatus(
				customerId, 
				clientInvitationId,
				changeActiveRequest.active
			)

		} catch (e) {
			console.error("Could not change client invitation active status. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.changeManualApprovalRequest]({ commit }, changeManualApprovalRequest: ChangeManualApprovalRequest) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedClientInvitation?.id
			if(!clientInvitationId){
				console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation
				return;
			}

			await client.changeClientInvitationManualApprovalStatus(
				customerId, 
				clientInvitationId,
				changeManualApprovalRequest.manualApproval
			)
		} catch (e) {
			console.error("Could not change client invitation manual approval status. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.approveClientRegistration]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedRegistration?.linkOnboardingId
			const linkregistrationid = (store as Store).state.context.selectedRegistration?.jobId

			if(!clientInvitationId){
				console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation
				return;
			}

			await client.approveRegistration(
				customerId, 
				clientInvitationId,
				linkregistrationid
			)
		} catch (e) {
			console.error("Could not approve client registration. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.rejectClientRegistration]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedRegistration?.linkOnboardingId
			const linkregistrationid = (store as Store).state.context.selectedRegistration?.jobId

			if(!clientInvitationId){
				console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation
				return;
			}

			await client.rejectRegistration(
				customerId, 
				clientInvitationId,
				linkregistrationid
			)
		} catch (e) {
			console.error("Could not reject client registration. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	[ActionTypes.setCustomer]({ commit }, customer: Domain | null) {
		if (customer && customer.tenantType === "Customer") commit(MutationTypes.SET_CUSTOMER, customer)
		else commit(MutationTypes.SET_CUSTOMER, null)
	},
	[ActionTypes.setClient]({ commit }, client: Client | null) {
		if (client) commit(MutationTypes.SET_CLIENT, client)
		else commit(MutationTypes.SET_CLIENT, null)
	},
	async [ActionTypes.getCustomerInfo]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const response = await client.getCustomerInfo(customerId)
			commit(MutationTypes.SET_CUSTOMER_INFO, response)
		} catch (e) {
			console.error("Could not get company info. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CUSTOMER_INFO, null)
		}
	},
	async [ActionTypes.getClientInfo]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id
			const response = await client.getClientInfo(customerId, clientId)
			console.log(response)
			commit(MutationTypes.SET_CLIENT_INFO, response)
		} catch (e) {
			console.error("Could not get client info. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CLIENT_INFO, null)
			throw (e)
		}
	},
	[ActionTypes.setClientInvitation]({ commit }, invitation: ClientInvitation | null) {
		if (invitation) commit(MutationTypes.SET_CLIENT_INVITATION, invitation)
		else commit(MutationTypes.SET_CLIENT_INVITATION, null)
	},
	[ActionTypes.setRegistration]({ commit }, registration: ClientInvitationRegistration | null) {
		if (registration) commit(MutationTypes.SET_REGISTRATION, registration)
		else commit(MutationTypes.SET_REGISTRATION, null)
	},
	async [ActionTypes.deleteRegistration]({ commit, dispatch }, registration: ClientInvitationRegistration | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			//const clientId = (store as Store).state.context.selectedClient?.id
			const clientRegistrationId = (store as Store).state.context.selectedClientInvitation?.id

			const infoId = (store as Store).state.context.selectedClientInvitationInfo?.jobId
			if(!infoId){
				throw("selectedClientInvitationInfo has failed to load.")
			} else if (
				clientRegistrationId !== infoId
				|| !registration
			){ 
				throw("Could not determine clientRegistrationId.")
			}

			//const personId = (store as Store).state.context.selectedPerson?.id
			const response = await client.deleteRegistration(customerId, clientRegistrationId, registration)
		} catch (e) {
			throw `Could not remove person from customer. Error: ${JSON.stringify(e)} `
		}
	},
	async [ActionTypes.getClientInvitationInfo]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedClientInvitation?.id
			if(!clientInvitationId) console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation

			const response = await client.getClientInvitationInfo(customerId, clientInvitationId)
			commit(MutationTypes.SET_CLIENT_INVITATION_INFO, response)
		} catch (e) {
			console.error("Could not get client invitation info. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CLIENT_INVITATION_INFO, null)
		}
	},
	async [ActionTypes.getClientInvitationRegistrations]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientInvitationId = (store as Store).state.context.selectedClientInvitation?.id
			if(!clientInvitationId) console.warn("No client invitation has been selected.") // have not dispatched context/setClientInvitation
			const response = await client.getClientInvitationRegistrations(customerId, clientInvitationId)
			commit(MutationTypes.SET_CLIENT_INVITATION_REGISTRATIONS, response.results)
		} catch (e) {
			console.error("Could not get client invitation registrations. Error: " + JSON.stringify(e))
			commit(MutationTypes.SET_CLIENT_INVITATION_REGISTRATIONS, [])
		}
	},
    async [ActionTypes.getAnyDomainAcl]({ commit }, moduleName: string) {
		try {
            let acl: AclResponse | null = state.anyDomainAcl

            if(!acl){
                const client = new ApiClient()
                const response: AclResponse  = await client.getModuleAclAnyDomain(moduleName)
                commit(MutationTypes.SET_ANY_DOMAIN_ACL, response)
            }

            acl = state.anyDomainAcl

            const tenantOnboardingAcl = acl?.ui.find( ({ name }) => name === OnboardingUiEnum.createTenant)
            const isAllowed = tenantOnboardingAcl?.roles.includes(OnboardingRoles.tenantOnboarder) ?? false
            console.log("hasCreateTenantAccess: ", isAllowed)

            const anyDomainPermissions: AnyDomainPermissions = {
                isTenantOnboarder: isAllowed
            }

            commit(MutationTypes.SET_ANY_DOMAIN_PERMISSIONS, anyDomainPermissions)

		} catch (e) {
            const errorMessage = JSON.stringify(e)
            if(errorMessage.includes("401")){
                console.warn("Unauthorized. Most likely caused by failure to obtain the oidc-credentials in time. " + JSON.stringify(e))
            }else {
                console.error("Could not get ACL for any domain. Error: " + JSON.stringify(e))
            }			
			commit(MutationTypes.SET_ANY_DOMAIN_ACL, null)
		}
	},
	async [ActionTypes.setClientName]({ commit }, info: CompanyInfo | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id

			await client.updateClientName(customerId, clientId, info)

			const newCustomerClients = (store as Store).state.context.customerClients
			for(let i = 0; i < newCustomerClients.length; i++){
				if(newCustomerClients[i].id == clientId && info?.name) newCustomerClients[i].name = info?.name
			}
			commit(MutationTypes.SET_CUSTOMERCLIENTS, newCustomerClients)
			
			const newClient = (store as Store).state.context.selectedClient
			if(newClient && info?.name) newClient.name = info?.name as string
			commit(MutationTypes.SET_CLIENT, newClient)

			const newClientInfo = (store as Store).state.context.selectedClientInfo
			if(newClientInfo && info?.name) newClientInfo.name = info?.name as string
			commit(MutationTypes.SET_CLIENT_INFO, newClientInfo)
		} catch (e) {
			console.error("Could not update client info. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.setClientDescription]({ commit }, info: CompanyInfo | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id

			await client.updateClientDescription(customerId, clientId, info)

			const newClientInfo = (store as Store).state.context.selectedClientInfo
			if(newClientInfo && info?.description) newClientInfo.description = info?.description as string
			commit(MutationTypes.SET_CLIENT_INFO, newClientInfo)
		} catch (e) {
			console.error("Could not update client info. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.setCompanyName]({ commit }, info: CompanyInfo | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id

			await client.updateCompanyName(customerId, info)
		} catch (e) {
			console.error("Could not update company info. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.setCompanyDescription]({ commit }, info: CompanyInfo | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id

			await client.updateCompanyDescription(customerId, info)
		} catch (e) {
			console.error("Could not update company info. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.setCompanyBusinessRegistryId]({ commit }, info: CompanyInfo | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id

			await client.updateCompanyBusinessRegistryId(customerId, info)
		} catch (e) {
			console.error("Could not update company info. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.setPrimaryContact]({ commit }, primaryContactId: string | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			await client.updatePrimaryContact(customerId, primaryContactId)
		} catch (e) {
			throw `Could not set primary contact. Error: ${JSON.stringify(e)} `
		}
	},
	async [ActionTypes.setClientPrimaryContact]({ commit }, primaryContactId: string | null) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = (store as Store).state.context.selectedClient?.id

			await client.updateClientPrimaryContact(customerId, clientId, primaryContactId)
		} catch (e) {
			throw `Could not set primary contact. Error: ${JSON.stringify(e)} `
		}
	},
	async [ActionTypes.reassignPerson]({ commit }, reassignPersonRequest: ReassignPersonRequest) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = reassignPersonRequest.targetClientId + "";
			const personId = reassignPersonRequest.person.id + "";

			await client.reassignPersonToClient(customerId, clientId, personId, reassignPersonRequest.targetClientId, reassignPersonRequest.newClientName)
		} catch (e) {
			console.error("Could not reassign customer person to client. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.reassignClientPerson]({ commit }, reassignClientPersonRequest: ReassignClientPersonRequest) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const clientId = reassignClientPersonRequest.client.id;
			const personId = reassignClientPersonRequest.person.id;

			await client.reassignClientPerson(
				customerId, 
				clientId, 
				personId,
				reassignClientPersonRequest.targetOrganizationId,
			)
		} catch (e) {
			console.error("Could not reassign customer person to client. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.findPersonAccount]({ commit, dispatch },request: FindPersonAccountRequest) {
		try {
			const client = new ApiClient()
			const response: FindPersonAccountResponse = await client.findPersonAccount(request)
			commit(MutationTypes.SET_FIND_ACCOUNT_RESULTS, response.results)
		} catch (e) {
			console.warn("Could not resolve domains request. Error: " + JSON.stringify(e))
		}
	},
	async [ActionTypes.accountSummary]({ commit, dispatch }, personId: string) {
		try {
			const client = new ApiClient()
			const response: GetAccountDetails = await client.getAccountSummary(personId)
			commit(MutationTypes.SET_SELECTED_ACCOUNT_DETAILS, response)
		} catch (e) {
			console.warn("Could not retrieve account details for: "+ personId + ". Error: " + JSON.stringify(e))
			throw (e)
		}
	},
	async [ActionTypes.removeAccount]({ commit, dispatch }, personId: string) {
		try {
			const client = new ApiClient()
			const response= await client.removeAccount(personId)
		} catch (e) {
			console.warn("Could not resolve domains request. Error: " + JSON.stringify(e))
			throw (e)
		}
	},
    async [ActionTypes.createTenant]({ commit }, createTenantPayload: CreateTenantPayload): Promise<void | string | AxiosError> {

        const inviterUserId = (store as Store).state.oidcStore.user ? (store as Store).state.oidcStore.user.sub : ""
        const inviterDisplayName = (store as Store).state.oidcStore.user.preferred_username ?? ""
        
        createTenantPayload.invitingPersonDisplayName = inviterDisplayName
        createTenantPayload.invitingPersonId = inviterUserId
        createTenantPayload.tenantId = uuidv4()
        createTenantPayload.tenantOnboardingId = uuidv4()
        commit(MutationTypes.SET_CREATE_TENANT_FORM_DATA, createTenantPayload)
        //createTenantPayload.phoneNumber = ""
		try {
			const client = new ApiClient()
            
            const response = await client.createTenantOnboarding(createTenantPayload)

            console.log("Create Tenant response: ", response)
            return JSON.stringify(response)
		} catch (e: any | AxiosError) {
            console.error("Could not create new tenant onboarding. Error: " + JSON.stringify(e))
            if(axios.isAxiosError(e)){
                console.log("Response: ", e.response)
                const responseStatus = e.response?.status
                // We want to return 400 in case there are some validation errors which should be prompted to the users
                if(responseStatus === 400){
                    return e
                }
            }
           throw (e)
		}
	},
	[ActionTypes.setSelectedAccount]({ commit }, account: AccountItem | null) {
		if (account) commit(MutationTypes.SET_SELECTED_ACCOUNT, account)
		else commit(MutationTypes.SET_SELECTED_ACCOUNT, null)
	},
    [ActionTypes.setCreateTenantFormData]({ commit }, createTenantFormData: CreateTenantPayload){
        console.log("Updating createTenantData: ", createTenantFormData)
        if (createTenantFormData) commit(MutationTypes.SET_CREATE_TENANT_FORM_DATA, createTenantFormData)
        else commit(MutationTypes.SET_CREATE_TENANT_FORM_DATA, {} as CreateTenantPayload)
    },
    [ActionTypes.setAnyDomainPermissions]({ commit }, anyDomainPermissions: AnyDomainPermissions | null) {
		if (anyDomainPermissions) commit(MutationTypes.SET_ANY_DOMAIN_PERMISSIONS, anyDomainPermissions)
		else commit(MutationTypes.SET_ANY_DOMAIN_PERMISSIONS, { isTenantOnboarder: false} as AnyDomainPermissions)
	},
	async [ActionTypes.updateAccountResults]({ commit }, newResults: AccountItem[]) {
		commit(MutationTypes.SET_ACCOUNT_RESULTS, newResults)
	},
    [ActionTypes.resetCreateTenantFormData]({ commit }, u?: undefined){
        commit(MutationTypes.RESET_CREATE_TENANT_FORM_DATA, u)
    },
	[ActionTypes.resetContext]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CONTEXT, u)
	},
	[ActionTypes.resetPersons]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_PERSONS, u)
	},
	[ActionTypes.resetCustomerClients]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CUSTOMERCLIENTS, u)
	},
	[ActionTypes.resetAssociatedClients]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_ASSOCIATEDCLIENTS, u)
	},
	[ActionTypes.resetCustomerInfo]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CUSTOMER_INFO, u)
	},
	[ActionTypes.resetClientInfo]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CLIENT_INFO, u)
	},
	[ActionTypes.resetClientPersons]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CLIENTPERSONS, u)
	},
	[ActionTypes.resetClientInvitations]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CLIENT_INVITATIONS, u)
	},
	[ActionTypes.resetClientInvitationInfo]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_CLIENT_INVITATION_INFO, u)
	},
	[ActionTypes.resetRegistration]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_REGISTRATION, u)
	},
	[ActionTypes.resetFindPersonAccount]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_FIND_ACCOUNT_RESULTS, u)
	},
	[ActionTypes.resetAccountSummary]({ commit }, u?: undefined) {
		commit(MutationTypes.RESET_SELECTED_ACCOUNT_DETAILS, u)
	},
}

// getters
export type Getters = {
	getContext(state: State): State
}
export const getters: GetterTree<State, State> & Getters = {
	getContext: (state) => {
		return state
	},
}

export type ContextMutations = Namespaced<Mutations, "context">
export type ContextActions = Namespaced<Actions, "context">
export type ContextGetters = Namespaced<Getters, "context">
export type ContextState = NamespacedState<State, "context">
