
import Vue from "vue"
import { toggleWidget } from "@/scripts/freshworks"
const UserMenu = () => import("@/components/profile/UserMenu.vue")
export default Vue.extend({
	name: "NavBar",
	components: {
		UserMenu,
	},
	computed: {
		hasContext(): boolean {
			return this.$vStore.state.context.selectedCustomer !== null
		},
	},
	methods: {
		logo(): void {
			if (!this.$vuetify.breakpoint.xsOnly) {
				return require("@/assets/verji/verji_connections_logo_long.svg")
			} else {
				return require("@/assets/verji/verji.svg")
			}
		},
		logoWidth(): number {
			return !this.$vuetify.breakpoint.xsOnly ? 160 : 70
		},
		supportRedirect(): void {
			toggleWidget();
		}
	},
})
