import { middlewareContext, middleware } from "./types"
import oidc from "./oidc"
import context from "./context"
import checkroute from "./checkroute"
import nav from "./nav"
import title from "./title"

export const guard = {
	open: 		[oidc, title],
	auth: 		[oidc, context, nav, title],
	checkroute: [oidc, context, nav, checkroute, title]
}

export const guardColor = {
	header: "background: #40E0D0; color: #FFFFFF",
	info: "background: #87CEEB; color: #FFFFFF",
	error: "background: #FFB6C1; color: #FFFFFF"
}

export function nextFactory(
	context: middlewareContext,
	middleware: middleware,
	index: number
) {
	const subsequentMiddleware = Array.isArray(middleware)
		? middleware[index]
		: null
	if (!subsequentMiddleware) return context.next

	return () => {
		// Run the default Vue Router `next()` callback first. NO - WHY? - this will triple up with starting renders. Can be seen if adding a beforeRouteEnter in the components..
		// context.next()
		// Then run the subsequent Middleware with a new `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(
			context,
			middleware,
			index + 1
		)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}
