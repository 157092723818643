import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import store, { Store } from "@/store"
import { nextFactory, guard, guardColor } from "./middleware"
import { middlewareContext, middleware } from "@/router/middleware/types"
import { aclWrapper } from "@/utilities/aclWrapper"

const Home = () => import("@/views/Home.vue")
const Context  = () => import("@/views/Context.vue")
/*
const GenericWrapper = () => import("@/views/GenericWrapper.vue")
const PogPaySlips = () => import("@/components/powerofficego/PogPaySlips.vue").then(m => m.default || m)
const PogFileArchive = () => import("@/components/powerofficego/PogFileArchive.vue").then(m => m.default || m)
const SignantWhitelabelSigning = () => import("@/components/documentsigning/SignantWhitelabelSigning.vue").then(m => m.default || m)
const SignyfySigning = () => import("@/components/documentsigning/SignifySigning.vue").then(m => m.default || m)
*/
const GenericWrapper = () => import("@/views/GenericWrapper.vue")

const PogPaySlips = () => import("@/components/powerofficego/PogPaySlips.vue").then(m => m.default || m)
const PogFileArchive = () => import("@/components/powerofficego/PogFileArchive.vue").then(m => m.default || m)

const Customer = () => import("@/views/Customer.vue").then(m => m.default || m)
const PersonClients = () => import("@/views/PersonClients.vue").then(m => m.default || m)

const Organization = () => import("@/views/Organization.vue").then(m => m.default || m)
const ClientInfo = () => import("@/views/ClientInfo.vue").then(m => m.default || m)
const Extensions = () => import("@/views/Extensions.vue").then(m => m.default || m)
const ExtensionItem = () => import("@/views/ExtensionItem.vue").then(m => m.default || m)
//const Profile = () => import("@/views/Profile.vue")
const LinkCollection = () => import("@/views/LinkCollection.vue").then(m => m.default || m)

const OidcCallback = () => import("@/components/OidcCallback.vue")
const OidcCallbackError = () => import("@/components/OidcCallbackError.vue")
const OidcCallbackSilentRenew = () => import("@/components/OidcCallbackSilentRenew.vue")
const Logout = () => import("@/views/Logout.vue")


const AccountOverview = () => import("@/views/AccountView.vue").then(m => m.default || m)
const AccountRemove = () => import("@/views/AccountRemove.vue").then(m => m.default || m)

const GenericView = () => import("@/views/GenericView.vue").then(m => m.default || m)
const Registrations = () => import("@/views/Registrations.vue").then(m => m.default || m)
const Invitation = () => import("@/views/Invitation.vue").then(m => m.default || m)

const ManageTenants = () => import("@/views/ManageTenants.vue").then(m => m.default || m)
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		},
	},
	{
		path: "/context",
		name: "Context",
		component: Context,
		meta: {
			middleware: guard.open,
			middlewareName: "CONTEXT",
		},
	},
    {
        path: "/tenant",
        name: "Manage Organizations",
        component: ManageTenants,
        meta: {
            middleware: guard.open,
            middlewareName: "CONTEXT",
            featureFlag: "manage-tenants"
        },
    },
	{
		path: "/manageaccount",
		name: "Manage Account",
		component: GenericView, // Change to generic view
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
    {
		
		path: "/manageaccount/remove",
		name: "Remove Account",
		component: AccountRemove,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
	{
		
		path: "/manageaccount/overview",
		name: "Account Overview",
		component: AccountOverview,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
    {
		path: "/invitations",
		name: "Invitations",
		component: GenericView, // Change to generic view
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
    {
		path: "/invitations/provision",
		name: "Invitation",
		component: aclWrapper(Invitation, "Invitation"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
    {
		path: "/invitations/linkonboarding",
		name: "Registrations",
		component: aclWrapper(Registrations, "Customer"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},

/* 	{
		path: "/invitation",
		name: "Invitation",
		component: aclWrapper(Invitation, "Invitation"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	}, */
	{
		path: "/customer",
		name: "Customer",
		component: aclWrapper(Customer, "Customer"),
		meta: {
			middleware: guard.auth,
		}
	},
	{
		path: "/clientorganization",
		name: "ClientOrganization",
		component: aclWrapper(PersonClients, "ClientOrganization"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH"
		}
	},
	{
		path: "/organization",
		name: "Organization",
		component: aclWrapper(Organization, "Organization"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH"
		}
	},
	{
		path: "/clientinfo",
		name: "ClientInfo",
		component: aclWrapper(ClientInfo, "ClientInfo"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH"
		}
	},
	/*
	These will probably be root router components when enabled in Extensions (and then dissappaer in Extensions because it's activated)
	{
		path: 'pogpayslips',
		name: "PogPaySlips",
		component: aclWrapper(PogPaySlips, "PogPaySlips"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		},
	},
	{
		path: 'pogfilearchive',
		name: "PogFileArchive",
		component: aclWrapper(PogFileArchive, "PogFileArchive"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		},
	},
	{
		path: 'signantwhitelabelsigning',
		name: "SignantWhitelabelSigning",
		component: aclWrapper(SignantWhitelabelSigning, "SignantWhiteLabelSigning"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		},
	},
	{
		path: 'signifysigning',
		name: "SignyfySigning",
		component: aclWrapper(SignyfySigning, "SignifySigning"),
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		},
	},
	
	{
		path: "/extensions",
		name: "Extensions",
		component: Extensions,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
	{
		
		path: "/extensions/:extensionItemId",
		name: "ExtensionItem",
		component: ExtensionItem,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
    */
	{
		path: "/securitybundle",
		name: "Security Bundle",
		component: LinkCollection,
		meta: {
			middleware: guard.auth,
			middlewareName: "AUTH",
		}
	},
	/*
	{
		path: "/profile",
		name: "Profile",
		component: Profile,
		meta: {
			middleware: guard.open,
			middlewareName: "AUTH",
		},
	},
	*/
	{
		path: "/oidc-callback", // Needs to match redirect_uri in your oidcSettings
		name: "OidcCallback",
		component: OidcCallback,
		meta: {
			isOidcCallback: true,
			isPublic: true,
		},
	},
	{
		path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
		name: "OidcCallbackError",
		component: OidcCallbackError,
		meta: {
			isPublic: true,
		},
	},
	{
		path: "/oidc-silent-renew", // Needs to match redirect_uri in you oidcSettings
		name: "OidcCallbackSilentRenew",
		component: OidcCallbackSilentRenew,
		meta: {
			isPublic: true,
		},
	},
	{
		path: "/logout",
		name: "Logout",
		component: Logout
	},
	{
		path: "*",
		meta: {
			middleware: guard.checkroute,
			middlewareName: "404",
		},
	},
]

const router = new VueRouter({
	routes,
	mode: "history"
})

/* Apply middleware guards */
router.beforeEach(async (to, from, next) => {
	if (to.meta && to.meta.middleware) {
		if ((store as Store).state.config.loggerMiddleware && to.meta.middlewareName && typeof to.meta.middlewareName === "string") console.log(`%c[***** MIDDLEWARE ${to.meta.middlewareName} *****]`, guardColor.header)
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
		const context: middlewareContext = {
			to,
			from,
			next,
			store: store as Store,
			router,
		}
		const nextMiddleware = nextFactory(context, ((middleware as unknown) as middleware
		), 1)
		return middleware[0]({ ...context, next: nextMiddleware })
	}

	return next()
})

export default router
