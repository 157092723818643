
import Vue from "vue"
import { UnionItemDefinition } from "@/types/responses"
import { Route } from "vue-router";
import { RouteTypes } from "@/types/enums"

export type groupmenu = {
	state: boolean
	id: string
}

export default Vue.extend({
	name: "NavDrawer",
	mounted() {
		this.setBorderWidth();
		this.setEvents();
	},
	watch: {
		$route(to: Route) {
			if (to.path.indexOf("extensions" || "manageaccount") !== -1) {
				this.groupmenu.forEach(e => {
					e.state = false;
				})
			}
		}
	},
	data(): {
		navigation: {
			width: number
			borderSize: number
		}
		groupmenu: Array<groupmenu>
	} {
		return {
			navigation: {
				width: 320,
				borderSize: 2,
			},
			groupmenu: []
		}
	},
	methods: {
		pushRoute(route: string): void {
			if(route == RouteTypes.None) return
			this.$router.push({path: route}).catch(() => { console.log("No route pushed.")})
		},
		expander(groupClick: groupmenu): void {
			this.groupmenu.forEach(e => {
				if(e.id == groupClick?.id){
					if (groupClick?.state) {
						e.state = false
					} else {
						e.state = !e.state
					}
				}
			});
		},
		getGroupModel(id: string): boolean {
			const group = this.groupmenu.find((e) => { return (e.id == id) })
			if(group) return group.state;
			return false;
		},
		isExtension(item: UnionItemDefinition): boolean {
			if (item.isExtension) return true
			return false
		},
		hasSubItems(item: UnionItemDefinition): boolean {
			return item.subItems.length > 0
		},
		getName(item: UnionItemDefinition): string {
			if (item.moduleName === "Home") return "Home"
			else return item.name
		},
		setBorderWidth() {
			// eslint-disable-next-line
			const i = (this.$refs.drawer as any).$el.querySelector(".v-navigation-drawer__border")
			i.style.width = this.navigation.borderSize + "px"
			i.style.cursor = "ew-resize"
			i.style.backgroundColor = "#EEE"
		},
		setEvents() {
			const minSize = this.navigation.borderSize
			// eslint-disable-next-line
			const el = (this.$refs.drawer as any).$el
			const drawerBorder = el.querySelector(".v-navigation-drawer__border")
			const direction = el.classList.contains("v-navigation-drawer--right") ? "right" : "left"

			function resize(e: { clientX: number }) {
				document.body.style.cursor = "ew-resize"
				const f = direction === "right" ? document.body.scrollWidth - e.clientX : e.clientX
				el.style.width = f + "px"
			}

			drawerBorder.addEventListener(
				"mousedown",
				(e: { offsetX: number }) => {
					if (e.offsetX < minSize && !this.$vStore.state.nav.isMini) {
						el.style.transition = "initial"
						document.addEventListener("mousemove", resize, false)
					}
				},
				false
			)
			document.addEventListener(
				"mouseup",
				() => {
					el.style.transition = ""
					this.navigation.width = el.style.width
					document.body.style.cursor = ""

					const w = el.style.width
					const wn = w.split("px")[0]
					document.removeEventListener("mousemove", resize, false)

					if (wn < 100) {
						this.$vStore.dispatch('nav/toggleNavMini', true)
						this.navigation.width = 53
					}

				},
				false
			)
		},
	},
})
