import { middlewareContext } from "./types"
import { guardColor } from "./index"
import { Store } from "@/store"

export default async ({ to, from, next, store, router }: middlewareContext): Promise<void> => {
	if (store.state.config.loggerMiddleware) console.log("%c[CONTEXT]", guardColor.info)

	let hasContext  = false
	if ((store as Store).state.context.selectedCustomer?.id) {
		hasContext = true
	}

	if (!hasContext) {
		if (store.state.config.loggerMiddleware) console.log(`%c[CONTEXT - NO CONTEXT]`, guardColor.error)
		// eslint-disable-next-line
		router.push({ path: "/context" }).catch(() => {})
		return
	} else {
		next()
	}
}