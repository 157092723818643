import AsyncError from "@/components/AsyncError.vue"
import AsyncLoading from "@/components/AsyncLoading.vue"
import { AsyncComponentPromise, Component } from "vue/types/options"
import store, { Store } from "@/store"
import Vue, { VNode } from "vue"
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"

export const aclWrapper = (componentPromise: AsyncComponentPromise, moduleName: string, preloadedModules?: Array<string>) =>
	Vue.extend({
		name: moduleName + "AclWrapper",
		created() {
			this.load()
		},
		computed: {
			acl(): boolean {
				return this.$vStore.state.acl[moduleName] ? true : false
			},
		},
		watch: {
			acl(newPermissions, oldPermissions) {
				// always propagate new ACL if ACL flushed
				if (newPermissions === false) {
					this.component = null // delete the old component to show loading state
					this.load()
				}
			},
		},
		data(): {
			component: null | Component
			error: boolean
			errorMessage: string
			errorTitle: string
		} {
			return {
				component: null,
				error: false,
				errorMessage: "",
				errorTitle: ""
			}
		},
		methods: {
			async componentImport(componentPromise: AsyncComponentPromise, moduleName: string): Promise<Component> {
				return new Promise((resolve, reject) => {
					(async () => {
						try {
							const hasPermission = await this.getPermissions(moduleName)
							if (!hasPermission) {
								reject(`Access to module "${moduleName}" is not allowed`)
							}
							if (preloadedModules) {
								console.log('preloading extramodules')
								await Promise.all(preloadedModules.map(async (module) => {
									await this.$vStore.dispatch("acl/updateACL", module)
								}));
							}
							(componentPromise(
								// eslint-disable-next-line
								(_resolve) => {},
								// eslint-disable-next-line
								(_reject) => {}
							) as Promise<Component>).then((m) => resolve(m))
						} catch (e) {
							reject("Uknown Error fetching permissions: " + JSON.stringify(e))
						}
					})()
				})
			},
			async getPermissions(moduleName: string): Promise<boolean> {
				//check if stored
				const hasState =
					(store as Store).getters["acl/getAcl"][moduleName] &&
					(store as Store).getters["acl/getAcl"][moduleName].aclDomain &&
					(store as Store).state.context.selectedCustomer &&
					(store as Store).getters["acl/getAcl"][moduleName].aclDomain === (store as Store).state.context.selectedCustomer?.id //??
				if (hasState) {
					return true
				}

				await this.$vStore.dispatch("acl/updateACL", moduleName)

				//doublecheck updated state
				const isStored = (store as Store).getters["acl/getAcl"][moduleName]
				if (isStored) {
					return true
				} else {
					return false
				}
			},
			load(): void {
				this.error = false
				this.componentImport(componentPromise, moduleName)
					.then((component) => {
						this.component = component
					})
					.catch((reason) => {
						this.errorTitle = moduleName + "- NO ACCESS"
						this.errorMessage = reason
						this.error = true
					})
			},
		},
		render(h): VNode {
			if (this.component) {
				this.errorMessage = ""
				return h(this.component)
			} else if (this.error) {
				return h(AsyncError, {
					props: {
						reload: this.load,
						errorMessage: this.errorMessage,
						errorTitle: this.errorTitle
					},
				})
			} else {
				this.errorMessage = ""
				this.errorTitle = ""
				return h(AsyncLoading)
			}
		},
	})
