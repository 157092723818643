
import Vue from "vue"
import { ModuleName } from "./types/enums"
export default Vue.extend({
	name: "App",
	computed: {
		user(): boolean {
			return this.$vStore.getters["oidcStore/oidcUser"] && this.$route.path !== "/oidc-callback"
		},
		hasContext(): boolean {
			const hasCtx = this.$vStore.state.context.selectedCustomer !== null

			const shouldShow = this.$route.path !== "/context" && this.$route.path !== "/profile" && this.$route.path !== "/tenant"
			return hasCtx && shouldShow
		},
	},
    watch: {
        user(newValue){
            if(newValue){                
                // Get ACL on user level when User is avaiable
                this.$store.dispatch("context/getAnyDomainAcl", ModuleName.onboarding);
            }
        }
    },
	async mounted(){
		this.attachFreshworksWidget();
	},
	methods: {
		attachFreshworksWidget(): void {
			const head = document.querySelector("head") as HTMLInputElement;
			const scriptExt = document.createElement("script");
			scriptExt.setAttribute("src", "https://euc-widget.freshworks.com/widgets/80000005404.js");
			scriptExt.async = true;
			scriptExt.defer = true;
			if(head !== null) {
				head.appendChild(scriptExt);
			}
		}
	}
})
