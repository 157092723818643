
import Vue from "vue"

export default Vue.extend({
	props: {
		msg: String,
		noSelector: Boolean // Deactivate customer selector
	},
	watch: {
		customerName: {
            deep: true,
            handler(){
                console.log("updated selected customer")
            }
        }
	},
	computed: {
		customerName(): string {
			if (this.$vStore.state.context.selectedCustomer) {
				return this.$vStore.state.context.selectedCustomer.name
			} else {
				return ""
			}
		}
	},
	methods: {
		loadContext(): void {
			this.$router.push('/context')
		}
	}
})
