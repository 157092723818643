
import Vue from "vue"
export default Vue.extend({
	props: {
		reload: {
			type: Function,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		errorTitle: {
			type: String,
			required: false,
		},
	},
	methods: {
		onReload() {
			this.reload && this.reload()
		},
	},
	computed: {
		customerName(): string {
			if (this.$vStore.state.context.selectedCustomer) {
				return this.$vStore.state.context.selectedCustomer.name
			} else {
				return "none"
			}
		},
	},
})
