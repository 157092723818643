import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex"
import { Config } from "@/types/config"
import { Namespaced, NamespacedState } from "@/types/functionTypes"

// state
export type State = Config

export const state: State = {
	//vmxAccountBaseUrl: null,
    registrationBaseUrl: "",
    verjiAccountUrl: "",
    verjiLinkOnboardingUrl: "",
    verjiSigningUrl: "",
    verjiAclUrl: "",
    verjiItopsUrl: "",
	authority: "",
	clientId: "",
	redirectUri: "",
	responseType: "",
	scope: "",
	silentRedirectUri: "",
	automaticSilentRenew: false,
	automaticSilentSignin: false,
	logoutlocation: "",
	loggerVuex: false,
	loggerMiddleware: false,
	loggerOidc: false,
    growthbookConfig: {apiHost: "", clientKey: ""}
}

// mutations
enum MutationTypes {
	UPDATE_CONFIG = "UPDATE_CONFIG"
}
export type Mutations<S = State> = {
	[MutationTypes.UPDATE_CONFIG](state: S, config: Config): void
}
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.UPDATE_CONFIG](state: State, config: Config) {
		Object.assign(state, config);
	}
}

// actions
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, "commit">
enum ActionTypes {
	updateConfig = "updateConfig"
}
export interface Actions {
	[ActionTypes.updateConfig]({ commit }: AugmentedActionContext, config: Config): void
}

export const actions: ActionTree<State, State> & Actions = {
	[ActionTypes.updateConfig]({ commit }, config) {
		commit(MutationTypes.UPDATE_CONFIG, config)
	}
}

// getters
export type Getters = {
	getConfig(state: State): null|Config
}

export const getters: GetterTree<State, State> & Getters = {
	getConfig: (state) => {
		return state
	}
}

export type ConfigMutations = Namespaced<Mutations, "config">
export type ConfigActions = Namespaced<Actions, "config">
export type ConfigGetters = Namespaced<Getters, "config">
export type ConfigState = NamespacedState<State, "config">
