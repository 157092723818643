import Vue from "vue"
import { upperFirst, camelCase } from "lodash"

// Load global components dynamically
const requireContext = require.context("./", false, /.*\.vue$/)
requireContext.keys().forEach((file) => {
	const componentConfig = requireContext(file)

	const f: string[] = file.split("/")
	const fName: string = f.pop() as string
	const componentName = upperFirst(
		camelCase(
			// Gets the file name regardless of folder depth
			fName.replace(/\.\w+$/, "")
		)
	)

	if (componentName) {
		// Register component globally
		Vue.component(
			componentName,
			// Look for the component options on `.default`, which will
			// exist if the component was exported with `export default`,
			// otherwise fall back to module's root.
			componentConfig.default || componentConfig
		)
	}
})
