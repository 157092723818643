import { middlewareContext } from "./types"
import { guardColor } from "./index"
import Vue from "vue"
import { UnionItemDefinition } from "@/types/responses"

export default async ({ to, from, next, store, router }: middlewareContext): Promise<void> => {
	if (store.state.config.loggerMiddleware) console.log("%c[TITLE]", guardColor.info)

	const appTitle = "Portal"
	const toRouteName: string = to.name ? to.name : ""
	let pageTitle = ""
	if (toRouteName === "Context")
		pageTitle = "Context"
	if (toRouteName === "Home")
		pageTitle = "Home"

	const tryTitle = (item: UnionItemDefinition) => {
		if (item.subItems.length > 0) {

			if (item.moduleName === "Extensions") {
				
				if (toRouteName === "Extensions") {
					pageTitle = item.name
					return true
				}

				const subHit = item.subItems.find((subItem, subItemIndex) => {
					return (subItem.isModule && subItem.moduleName === "ExtensionItem" && (parseInt(to.params.extensionItemId) === subItemIndex))
				})
				if (subHit)
					pageTitle = subHit.name
				return subHit ? true : false
			} else {
				const subHit = item.subItems.find(subItem => {
					return (subItem.isModule && subItem.moduleName === toRouteName)
				})
				if (subHit) 
					pageTitle = subHit.name
				return subHit ? true : false
			}
		} else {
			if (item.isModule && item.moduleName === toRouteName) {
				pageTitle = item.name
				return true
			} else {
				return false
			}
		}
	}

	if (store.state.nav.modules && pageTitle === "") {
		store.state.nav.modules.items.some(item => {
			return tryTitle(item)
		})
	}
    Vue.nextTick(() => {
        document.title = `${appTitle} | ${pageTitle}`
    });
	next()
}