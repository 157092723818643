export const toggleWidget = () => {
    const iframe = document.getElementById('widget-frame');
    if (iframe) {
        window.FreshworksWidget('close');
    } else {
        window.FreshworksWidget('open');
    }
}

const init = () => {
    window.fwSettings={
        'widget_id': 80000005404,
        'locale': 'en'
    };
    
	const anonFunc = () => {
		if(typeof window.FreshworksWidget !== "function" ) {
			// eslint-disable-next-line
            const n = function() { (n as any).q.push(arguments)};
			// eslint-disable-next-line
			(n as any).q=[],window.FreshworksWidget=n
        }
	}

    if (anonFunc)
		anonFunc()

    window.FreshworksWidget('hide', 'launcher');
}

init();