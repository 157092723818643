import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex"
import { Namespaced, NamespacedState } from "@/types/functionTypes"
import { frontendModules } from "@/constants/modules"
import { dummyBackendModules } from "@/constants/modules"
import { ApiClient } from "@/api/apiClient"
import store, { Store } from "@/store"
import { NavResponse, Modules, BackendItemDefinition, FrontendItemDefinition, UnionItemDefinition } from "@/types/responses"
import { findIndex, indexOf, union } from "lodash"

/* this.$store types in SFC's is being removed in Vuex4
 * "When using TypeScript, you must provide your own augment declaration"
 * Example from https://dev.to/3vilarthas/vuex-typescript-m4j
 * Keep it simpler and define all types, mutations, actions etc in one file
 */

const mergeFrontAndBackModules = (frontendArray: FrontendItemDefinition[], backendArray: BackendItemDefinition[]): { unionmodules: UnionItemDefinition[], allowedPaths: string[] } => {
	const modules: UnionItemDefinition[] = []
	const allowedPaths: string[] = []
	console.log("FRONTEND")
	console.log(frontendArray)
	console.log("BACKEND")
	//const testarr: BackendItemDefinition[] = [];
	
	//backendArray.forEach((item) => { testarr.push(item)})
    //console.log(testarr)
	frontendArray.forEach((frontendItem) => {
		/********/
		/* HOME */
		/********/
		if (frontendItem.moduleName === "Home") {
			modules.push(frontendItem as UnionItemDefinition) // force typehint this - we need just the route and icon here
			allowedPaths.push(frontendItem.route)
		}
		
		// Find: Not based on predetermined moduleName
		let _index = -1;
		const expandable = backendArray.find((backendItem, index) => {
			if(backendItem.isModule || backendItem.isExtension || backendItem.subItems.length < 1 || frontendItem.subItems.length < 1) return undefined
			for(let i = 0; backendItem.subItems.length > i; i++){
				if(backendItem.subItems[i].moduleName != frontendItem.subItems[i].moduleName) return undefined
			}
			const union = { ...backendItem, ...frontendItem }
			union.subItems = [];
			for(let i = 0; backendItem.subItems.length > i; i++){
				const subitemUnion = { ...backendItem.subItems[i], ...frontendItem.subItems[i] }
				if(subitemUnion.isPermitted){
					union.subItems.push(subitemUnion)
					allowedPaths.push(subitemUnion.route)
				}
			}
			if(union.subItems.length > 0){
				modules.push(union as UnionItemDefinition)
				allowedPaths.push(union.route)
			}
			
			_index = index;
			return backendItem
		})
		if(_index > -1){
			backendArray.splice(_index, 1);
			return;
		}

		// Find: Based on moduleName
		const standaloneModule = backendArray.find((backendItem) => {
			return (backendItem.moduleName === frontendItem.moduleName && backendItem.isModule && !backendItem.isExtension) || (backendItem.moduleName === frontendItem.moduleName && backendItem.isModule && !backendItem.isExtension && backendItem.moduleName === "ClientInfo")
		})
		const extensionsModule = backendArray.find((backendItem) => {
			return (backendItem.moduleName === frontendItem.moduleName && backendItem.isModule && backendItem.isExtension && frontendItem.subItems.length === backendItem.subItems.length && frontendItem.subItems.length > 0)
		})

		if (standaloneModule) {
			/**************/
			/* STANDALONE */
			/**************/
			if (standaloneModule.isPermitted) {
				const union = { ...standaloneModule, ...frontendItem }
				
				/**************/
				/* SUBITEMS */
				/**************/
				union.subItems = [];
				const subitemModule: UnionItemDefinition | null = null
				const nestedSubItems: UnionItemDefinition[] = []
				frontendItem.subItems.forEach((FrontendItemSubItem, frontendItemSubItemIndex) => {
					backendArray.forEach((backendItem) => {
						backendItem.subItems.forEach((BackendItemSubItem, BackendItemSubItemIndex) => {
							if (frontendItemSubItemIndex === BackendItemSubItemIndex && FrontendItemSubItem.moduleName === BackendItemSubItem.moduleName && BackendItemSubItem.isModule && BackendItemSubItem.isExtension) {
								const subitemUnion = { ...BackendItemSubItem, ...FrontendItemSubItem }
								if(subitemUnion.isPermitted){
									union.subItems.push(subitemUnion)
									allowedPaths.push(subitemUnion.route)
								}
							}
						})
					})
				})
				modules.push(union as UnionItemDefinition)
				allowedPaths.push(union.route)
			}
		} else if (extensionsModule) {
			/******************/
			/* EXTENSIONITEMS */
			/******************/
			let extensionModule: UnionItemDefinition | null = null
			const nestedSubItems: UnionItemDefinition[] = []
			frontendItem.subItems.forEach((FrontendItemSubItem, frontendItemSubItemIndex) => {
				backendArray.forEach((backendItem) => {
					backendItem.subItems.forEach((BackendItemSubItem, BackendItemSubItemIndex) => {
						if (frontendItemSubItemIndex === BackendItemSubItemIndex && FrontendItemSubItem.moduleName === BackendItemSubItem.moduleName && BackendItemSubItem.isModule && BackendItemSubItem.isExtension) {
							if (!extensionModule) extensionModule = { ...backendItem, ...frontendItem } as UnionItemDefinition
							const union = { ...BackendItemSubItem, ...FrontendItemSubItem }
							nestedSubItems.push(union as UnionItemDefinition)
							allowedPaths.push(union.route)
						}
					})
				})
			})
			/**************/
			/* ADD EXTENSTIONITEMS TO EXTENSIONS MODULE*/
			/**************/
			if (extensionModule && extensionsModule.isPermitted) {
				(extensionModule as UnionItemDefinition).subItems = nestedSubItems
				allowedPaths.push((extensionModule as UnionItemDefinition).route)
				modules.push(extensionModule)
			}
		}

	})
	console.log("Modules: ", modules)
	console.log("Allowed Paths: ", allowedPaths)
	return {
		unionmodules: modules,
		allowedPaths: allowedPaths,
	}
}

const defaultState = {
	isOpen: null,
	isMini: null,
	modules: null,
	allowedPaths: [],
	linkCollection: []
}

// state
type State = {
	isOpen: null | boolean
	isMini: null | boolean
	modules: null | Modules
	allowedPaths: string[]
	linkCollection: string[]
}
export const state: State = { ...defaultState }

// mutations
enum MutationTypes {
	TOGGLE_NAV = "TOGGLE_NAV",
	TOGGLE_NAV_MINI = "TOGGLE_NAV_MINI",
	SET_MODULES = "SET_MODULES",
	RESET_STATE = "RESET_STATE",
	SET_LINKCOLLECTION = "SET_LINKCOLLECTION"
}
export type Mutations<S = State> = {
	[MutationTypes.TOGGLE_NAV](state: S, isOpen: boolean): void
	[MutationTypes.TOGGLE_NAV_MINI](state: S, isMini: boolean): void
	[MutationTypes.SET_MODULES](state: S, navResponse: NavResponse|null): void
	[MutationTypes.RESET_STATE](state: S, u?: undefined): void
	[MutationTypes.SET_LINKCOLLECTION](state: S, linkCollection: string[]): void
}
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.TOGGLE_NAV](state: State, isOpen: boolean) {
		state.isOpen = isOpen
	},
	[MutationTypes.TOGGLE_NAV_MINI](state: State, isMini: boolean) {
		state.isMini = isMini
	},
	[MutationTypes.SET_MODULES](state: State, navResponse: NavResponse|null) {
		if (!navResponse) {
			state.allowedPaths = []
			state.modules = null
			return
		}
		const { unionmodules, allowedPaths } = mergeFrontAndBackModules(frontendModules, navResponse.items)
		state.modules = {
			tenantId: navResponse.tenantId,
			userId: navResponse.userId,
			items: unionmodules,
		}
		state.allowedPaths = allowedPaths
		const LinkCollection = unionmodules.find((e) => { return (e.moduleName == "LinkCollection")} )
		const links: string[] = []
		if(LinkCollection){
			for (const [key, v] of Object.entries(LinkCollection?.arguments as Record<string, any>)) {
				links.push(v);
				//links.push("www.google.com"); // Test 2 links
			}
		} 
		state.linkCollection = links
	},
	[MutationTypes.RESET_STATE](state: State, u?: undefined) {
		Object.assign(state, defaultState)
	},
	[MutationTypes.SET_LINKCOLLECTION](state: State, linkCollection: string[]) {
		state.linkCollection = linkCollection
	},
}

// actions
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, "commit">
enum ActionTypes {
	toggleNav = "toggleNav",
	toggleNavMini = "toggleNavMini",
	updateModules = "updateModules",
	resetState = "resetState",
	updateLinkCollection = "updateLinkCollection"
}
export interface Actions {
	[ActionTypes.toggleNav]({ commit }: AugmentedActionContext, isOpen: boolean): void
	[ActionTypes.toggleNavMini]({ commit }: AugmentedActionContext, isMini: boolean): void
	[ActionTypes.updateModules]({ commit }: AugmentedActionContext): Promise<void>
	[ActionTypes.resetState]({ commit }: AugmentedActionContext): void
	[ActionTypes.updateLinkCollection]({ commit }: AugmentedActionContext, linkCollection: string[]): void
}

export const actions: ActionTree<State, State> & Actions = {
	[ActionTypes.toggleNav]({ commit }, isOpen) {
		commit(MutationTypes.TOGGLE_NAV, isOpen)
	},
	[ActionTypes.toggleNavMini]({ commit }, isMini) {
		commit(MutationTypes.TOGGLE_NAV_MINI, isMini)
	},
	async [ActionTypes.updateModules]({ commit }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			if (customerId) {
			const response = await client.getNav(customerId)
			commit(MutationTypes.SET_MODULES, response)
			//commit(MutationTypes.SET_MODULES, dummyBackendModules)
			} else {
				commit(MutationTypes.SET_MODULES, null)
			}
		} catch(e) {
			console.log("Could not get navigation from backend. Error: " + JSON.stringify(e))
			//commit(MutationTypes.SET_MODULES, dummyBackendModules)
			commit(MutationTypes.SET_MODULES, null)
		}
	},
	[ActionTypes.resetState]({ commit }) {
		commit(MutationTypes.RESET_STATE, undefined)
	},
	[ActionTypes.updateLinkCollection]({ commit }, linkCollection) {
		commit(MutationTypes.SET_LINKCOLLECTION, linkCollection)
	},
}

// getters
export type Getters = {
	isOpen(state: State): null | boolean
	isMini(state: State): null | boolean
	modules(state: State): null | Modules
	linkCollection(state: State): null | string[]
}
export const getters: GetterTree<State, State> & Getters = {
	isOpen: (state) => {
		return state.isOpen
	},
	isMini: (state) => {
		return state.isMini
	},
	modules: (state) => {
		return state.modules
	},
	linkCollection: (state) => {
		return state.linkCollection
	},
}

export type NavMutations = Namespaced<Mutations, "nav">
export type NavActions = Namespaced<Actions, "nav">
export type NavGetters = Namespaced<Getters, "nav">
export type NavState = NamespacedState<State, "nav">
