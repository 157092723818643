import { middlewareContext } from "./types"
import { guardColor } from "./index"

export default ({ to, from, next, store, router }: middlewareContext): void => {
	if (store.state.config.loggerMiddleware) console.log(`%c[CHECKROUTE]`, guardColor.info)
	if (!to.name) {
		if (store.state.config.loggerMiddleware)
			console.log(`%c[CHECKROUTE - PAGE NOT FOUND - EJECT TO HOME]`, guardColor.error)
		// eslint-disable-next-line
		router.push({ path: "/" }).catch(() => {})
		return
	}
	next() // strictly not neccessary since it's the last catch all route
}
