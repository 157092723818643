/* Font Awesome with tree-shaking
 *
 * 1. Import needed Icon (Regular/outlined, Solid, Brand etc..)
 * 2. Add imported Icon to library
 */

import Vue from "vue"
import { library } from "@fortawesome/fontawesome-svg-core"
import { IconsOptions } from "vuetify/types/services/icons"
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome"

Vue.component("fa", FontAwesomeIcon)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.component("fa-layers", FontAwesomeLayers)
Vue.component("fa-layers-text", FontAwesomeLayersText)

/*********
 *** 1 ***
 ********/

// Solid icons
import { faDotCircle, faCheckCircle, faCircle, faCog, faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons"

// Override Vuetify's hardcoded prefix for radio buttons
faDotCircle.prefix = "far"
faCheckCircle.prefix = "far"
faCircle.prefix = "far"
faCog.prefix = "fas"
faEye.prefix = "fas"
faEyeSlash.prefix = "fas"

// PRO light icons overrided as FAR
import {
	faSquare,
} from "@fortawesome/pro-light-svg-icons"
// Override Vuetify's hardcoded prefix
faSquare.prefix = "far"

// PRO light icons
import {
	faRetweet,
	faFile,
	faList,
	faFileSignature,
	faSignOut,
	faUser,
	faUserClock,
	faUserTimes,
	faShield,
	faShieldAlt,
	faUserShield,
	faUserCheck,
    faUserPlus,
	faUserFriends,
	faUserEdit,
	faUsers,
	faUsersCog,
	faChevronDown,
	faChevronUp,
	faExchangeAlt,
	faPen,
	faHome,
	faLockOpen,
	faExclamation,
	faExclamationTriangle,
	faCaretLeft,
	faCaretRight,
	faCaretDown,
	faCaretUp,
	faSortUp,
	faSortDown,
	faCopy,
	faChevronLeft,
	faChevronRight,
	faCheckSquare,
	faCircleNotch,
	faPaperclip,
	faSearch,
	faBars,
	faPlus,
	faCloudDownloadAlt,
	faCloudUploadAlt,
	faShare,
	faEnvelope,
	faCheck,
	faCamera,
	faTimes,
	faTimesCircle,
	faHandPointLeft,
	faQuestionCircle,
	faSync,
	faInfoCircle
} from "@fortawesome/pro-light-svg-icons"

// Vuetify always expect "fas" (Free Font Solid Awesome), but use Pro fonts "fal" as "fas"
faRetweet.prefix = "fas"
faFile.prefix = "fas"
faList.prefix = "fas"
faFileSignature.prefix = "fas"
faSignOut.prefix = "fas"
faUser.prefix = "fas"
faHome.prefix = "fas"
faLockOpen.prefix = "fas"
faExclamation.prefix = "fas"
faExclamationTriangle.prefix = "fas"
faUserClock.prefix = "fas"
faUserTimes.prefix ="fas"
faShieldAlt.prefix = "fas"
faShield.prefix = "fas"
faUserShield.prefix = "fas"
faUserPlus.prefix = "fas"
faUserCheck.prefix = "fas"
faUserFriends.prefix = "fas"
faUserEdit.prefix = "fas"
faUsers.prefix  = "fas"
faUsersCog.prefix = "fas"
faChevronDown.prefix ="fas"
faChevronUp.prefix = "fas"
faExchangeAlt.prefix = "fas"
faPen.prefix = "fas"
faCaretLeft.prefix = "fas"
faCaretRight.prefix = "fas"
faCaretDown.prefix = "fas"
faCaretUp.prefix = "fas"
faSortUp.prefix = "fas"
faSortDown.prefix = "fas"
faCopy.prefix = "fas"
faChevronLeft.prefix = "fas"
faChevronRight.prefix = "fas"
faCheckSquare.prefix = "fas"
faCircleNotch.prefix = "fas"
faCheckCircle.prefix = "fas"
faPaperclip.prefix = "fas"
faSearch.prefix = "fas"
faBars.prefix = "fas"
faPlus.prefix = "fas"
faCloudDownloadAlt.prefix = "fas"
faCloudUploadAlt.prefix = "fas"
faShare.prefix = "fas"
faEnvelope.prefix = "fas"
faCheck.prefix = "fas"
faCamera.prefix = "fas"
faTimes.prefix = "fas"
faTimesCircle.prefix = "fas"
faHandPointLeft.prefix = "fas"
faQuestionCircle.prefix = "fas"
faSync.prefix = "fas"
faUserShield.prefix = "fas"
faInfoCircle.prefix = "fas"

/*********
 *** 2 ***
 ********/
library.add(
	// Solid icons
	faDotCircle,
	faCheckCircle,
	faCircle,
	faCog,

	// PRO override vuetify FAR
	faSquare,
	faShieldAlt,

	// PRO light/outlined icons
	faRetweet,
	faFile,
	faList,
	faFileSignature,
	faSignOut,
	faUser,
	faUserClock,
	faUserTimes,
	faShield,
	faUserShield,
    faUserPlus,
	faUserCheck,
	faUserFriends,
	faUserEdit,
	faUsers,
	faUsersCog,
	faChevronDown,
	faChevronUp,
	faExchangeAlt,
	faPen,
	faHome,
	faLockOpen,
	faExclamation,
	faExclamationTriangle,
	faCaretLeft,
	faCaretRight,
	faCaretDown,
	faCaretUp,
	faSortUp,
	faSortDown,
	faCopy,
	faEye,
	faEyeSlash,
	faChevronLeft,
	faChevronRight,
	faCheckSquare,
	faCircleNotch,
	faPaperclip,
	faSearch,
	faBars,
	faPlus,
	faCloudDownloadAlt,
	faCloudUploadAlt,
	faShare,
	faEnvelope,
	faCheck,
	faCamera,
	faTimes,
	faTimesCircle,
	faHandPointLeft,
	faQuestionCircle,
	faSync,
	faInfoCircle
)

/* For Append-Icons etc - replace custom icons */
/*
const CUSTOM_ICONS = {
  test: {
      component: FontAwesomeIcon,
      props: {
          icon: ['fas', 'eye']
      }
  },
};
*/

const FontAwesomeIcons: IconsOptions = {
	iconfont: "faSvg",
}

export { FontAwesomeIcons }
