import { NavResponse, AclResponse } from "@/types/responses"
import { RouteTypes } from "@/types/enums"

export const dummyACL: AclResponse = {
    aclDomain: "testen",
    moduleName: "Customer",
    modules: [
        {
            "name": "CustomerSettings",
            "roles": [
                "CustomerSettings-User"
            ]
        }
    ],
    ui: [
        {
            name: "primary-contact:display",
            roles: [
                "Customer-User"
            ]
        }
    ],
    hasPermission: true
}

export const dummyACLSub: AclResponse = {
	aclDomain: "testen",
	moduleName: "ClientOrganization",
	modules: [],
	ui: [
		{
			name: "primary-contact:display",
			roles: ["Customer-User"]
		}
	],
	hasPermission: true,
}

export const dummyInvitation: AclResponse = {
	aclDomain: "testen",
	moduleName: "Invitation",
	modules: [],
	ui: [
		{
			name: "primary-contact:display",
			roles: ["Customer-User"]
		}
	],
	hasPermission: true,
}

export const dummyOrganization: AclResponse = {
	aclDomain: "testen",
	moduleName: "Organization",
	modules: [],
	ui: [
		{
			name: "primary-contact:display",
			roles: ["Customer-User"]
		}
	],
	hasPermission: true,
}

/*
export const dummyBackendModules = {
	aclDomain: "testen",
	userId: "@cust1contact:verji.app",
	items: [
		{
			name: "Customer",
			moduleName: "Customer",
			description: "customer stuff",
			i18NNameKey: "nav-item-customer-name",
			i18NDescriptionKey: "nav-item-customer-description",
			isModule: true,
			isPermitted: true,
			isSubstituted: false,
			substitutionInfo: null,
			subItems: [],
		},
		{
			name: "Invitations",
			moduleName: "Invitation",
			description: "something here also",
			i18NNameKey: "nav-item-invitations-name",
			i18NDescriptionKey: "nav-item-invitation-description",
			isModule: true,
			isPermitted: true,
			isSubstituted: false,
			substitutionInfo: null,
			subItems: [],
		},
		{
			name: "Users & Clients",
			moduleName: "Organization",
			description: "Manage clients and users",
			i18NNameKey: "nav-item-organization-name",
			i18NDescriptionKey: "nav-item-organization-description",
			isModule: true,
			isPermitted: true,
			isSubstituted: false,
			substitutionInfo: null,
			subItems: [],
		},
		{
			name: "Power Office Go",
			moduleName: "",
			description: "Power Office Go Integrations",
			i18NNameKey: "nav-item-pog-root-name",
			i18NDescriptionKey: "nav-item-pog-root-description",
			isModule: false,
			isPermitted: true,
			isSubstituted: false,
			substitutionInfo: null,
			subItems: [
				{
					name: "Power Office Go Payslips",
					moduleName: "PogPaySlips",
					description: null,
					i18NNameKey: "nav-item-pog-payslips-name",
					i18NDescriptionKey: "nav-item-pog-payslips-description",
					isModule: true,
					isPermitted: true,
					isSubstituted: false,
					substitutionInfo: null,
					subItems: [],
				},
				{
					name: "Power Office Go File Archive",
					moduleName: "PogFileArchive",
					description: null,
					i18NNameKey: "nav-item-pog-file-archive-name",
					i18NDescriptionKey: "nav-item-pog-file-archive-description",
					isModule: true,
					isPermitted: true,
					isSubstituted: false,
					substitutionInfo: null,
					subItems: [],
				},
			],
		},
		{
			name: "Document Signing",
			moduleName: null,
			description: "Digitally sign documents",
			i18NNameKey: "nav-item-signatures-root-name",
			i18NDescriptionKey: "nav-item-signatures-root-description",
			isModule: false,
			isPermitted: true,
			isSubstituted: false,
			substitutionInfo: null,
			subItems: [
				{
					name: "Verji Signing",
					moduleName: "SignantWhitelabelSigning",
					description: "Sign documents in the Verji portal using BankID",
					i18NNameKey: "nav-item-verji-signatures-name",
					i18NDescriptionKey: "nav-item-verji-signatures-description",
					isModule: true,
					isPermitted: true,
					isSubstituted: false,
					substitutionInfo: null,
					subItems: [],
				},
				{
					name: "Signify Signing",
					moduleName: "SignyfySigning",
					description: "Sign documents in the Signify portal",
					i18NNameKey: "nav-item-signify-signatures-name",
					i18NDescriptionKey: "nav-item-signify-signatures-description",
					isModule: true,
					isPermitted: true,
					isSubstituted: false,
					substitutionInfo: null,
					subItems: [],
				},
			],
		},
	],
}
*/

export const dummyBackendModules: NavResponse = {
    "tenantId": "7548d7e5-f1cf-4aa1-87cb-0785083150c4",
    "userId": "@domtest05:synapse.localhost",
    "items": [
        {
			"arguments": {},
            "name": "Company",
            "moduleName": "Customer",
            "description": "View and manage customer info",
            "i18NNameKey": "nav-item-customer-name",
            "i18NDescriptionKey": "nav-item-customer-description",
            "isModule": true,
            "isPermitted": true,
            "isExtension": false,
            "isEnabled": true,
            "isSubstituted": false,
            "substitutionInfo": null,
            "subItems": []
        },
        {
			"arguments": {},
            "name": "Invitations",
            "moduleName": "Invitation",
            "description": "View, correct and resend invitations",
            "i18NNameKey": "nav-item-invitations-name",
            "i18NDescriptionKey": "nav-item-invitation-description",
            "isModule": true,
            "isPermitted": true,
            "isExtension": false,
            "isEnabled": true,
            "isSubstituted": false,
            "substitutionInfo": null,
            "subItems": []
        },
        {
			"arguments": {},
            "name": "Users & Clients",
            "moduleName": "Organization",
            "description": "Manage clients and users",
            "i18NNameKey": "nav-item-organization-name",
            "i18NDescriptionKey": "nav-item-organization-description",
            "isModule": true,
            "isPermitted": true,
            "isExtension": false,
            "isEnabled": true,
            "isSubstituted": false,
            "substitutionInfo": null,
            "subItems": []
        },
        {
			"arguments": {},
            "name": "Extensions",
            "moduleName": "Extensions",
            "description": "Verji extension modules",
            "i18NNameKey": "nav-item-extensions-root-name",
            "i18NDescriptionKey": "nav-item-extensions-root-description",
            "isModule": true,
            "isPermitted": true,
            "isExtension": true,
            "isEnabled": true,
            "isSubstituted": false,
            "substitutionInfo": null,
            "subItems": [
                {
					"arguments": {},
                    "name": "Power Office Go Payslips",
                    "moduleName": "ExtensionItem",
                    "description": "Run and configure distribution of payslips from Power Office Go",
                    "i18NNameKey": "nav-item-pog-payslips-name",
                    "i18NDescriptionKey": "nav-item-pog-payslips-description",
                    "isModule": true,
                    "isPermitted": true,
                    "isExtension": true,
                    "isEnabled": true,
                    "isSubstituted": false,
                    "substitutionInfo": null,
                    "subItems": []
                },
                {
					"arguments": {},
                    "name": "Power Office Go File Archiver Settings",
                    "moduleName": "ExtensionItem",
                    "description": "Settings to control Power Office Go Archiving ",
                    "i18NNameKey": "nav-item-pog-file-archive-name",
                    "i18NDescriptionKey": "nav-item-pog-file-archive-description",
                    "isModule": true,
                    "isPermitted": false,
                    "isExtension": true,
                    "isEnabled": true,
                    "isSubstituted": false,
                    "substitutionInfo": null,
                    "subItems": []
                },
                {
					"arguments": {},
                    "name": "Verji Signing",
                    "moduleName": "ExtensionItem",
                    "description": "Sign documents in the Verji portal using BankID",
                    "i18NNameKey": "nav-item-verji-signatures-name",
                    "i18NDescriptionKey": "nav-item-verji-signatures-description",
                    "isModule": true,
                    "isPermitted": false,
                    "isExtension": false,
                    "isEnabled": true,
                    "isSubstituted": false,
                    "substitutionInfo": null,
                    "subItems": []
                },
                {
					"arguments": {},
                    "name": "Custom Signing",
                    "moduleName": "ExtensionItem",
                    "description": "Custom document signing",
                    "i18NNameKey": "nav-item-signify-signatures-name",
                    "i18NDescriptionKey": "nav-item-signify-signatures-description",
                    "isModule": true,
                    "isPermitted": false,
                    "isExtension": true,
                    "isEnabled": true,
                    "isSubstituted": false,
                    "substitutionInfo": null,
                    "subItems": []
                }
            ]
        }
    ]
}
// the ModuleName is (and must be) the common identifier and allows us to merge frontend "modules" (icons, route, flex-width, router-view children for tabs component) and backend modules in Vuex
export const frontendModules = [
	{
		moduleName: "Home",
		route: "/",
		icon: "home",
		flex: 6,
		bgImage: "",
		subItems: [],
	},
	{
		moduleName: "ManageAccounts",
		route: "/manageaccount",
		icon: "user-shield",
		flex: 6,
		bgImage: "bg_1.webp",
		subItems: [
			{
				moduleName: "AccountRemove",
				route: "/manageaccount/remove",
				icon: "user-shield",
				flex: 6,
				bgImage: "bg_1.webp",
				subItems: []
			},
			{
				moduleName: "AccountOverview",
				route: "/manageaccount/overview",
				icon: "user-shield",
				flex: 6,
				bgImage: "bg_1.webp",
				subItems: []
			},
		]
	},
    {
		moduleName: "Invitations",
		route: "/invitations",
		icon: "user-plus",
		flex: 6,
		bgImage: "bg_1.webp",
		subItems: [
			{
				moduleName: "LinkOnboarding",
				route: "/invitations/linkonboarding",
				icon: "user-plus",
				flex: 6,
				bgImage: "bg_1.webp",
				subItems: []
			},
			{
				moduleName: "Invitation",
				route: "/invitations/provision",
				icon: "user-plus",
				flex: 6,
				bgImage: "bg_1.webp",
				subItems: []
			},
		]
	},
	{
		moduleName: "Customer",
		route: "/customer",
		icon: "users-cog",
		flex: 6,
		bgImage: "bg_1.webp",
		subItems: [
			{
				moduleName: "CustomerPersons",
				route: "/customer/persons",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
			{
				moduleName: "CustomerInvitations",
				route: "/customer/invitations",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
			{
				moduleName: "CustomerClients",
				route: "/customer/clients",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			}
		]
	},
	{
		moduleName: "ClientOrganization",
		route: "/clientorganization",
		icon: "users-cog",
		flex: 6,
		bgImage: "bg_1.webp",
		subItems: []
	},
/* 	{
		moduleName: "Invitation",
		route: "/invitation",
		icon: "user-clock",
		flex: 6,
		bgImage: "bg_2.webp",
		subItems: [],
	}, */
	{
		moduleName: "Organization",
		route: "/organization",
		icon: "users",
		flex: 6,
		bgImage: "bg_3.webp",
		subItems: [],
	},
	{
		moduleName: "ClientInfo",
		route: "/clientinfo",
		icon: "users",
		flex: 12,
		bgImage: "bg_3.webp",
		subItems: [],
	},
/* 	{
		moduleName: "Extensions",
		route: "/extensions",
		icon: "exchange-alt",
		flex: 12,
		bgImage: "bg_4.webp",
		subItems: [
			{
				moduleName: "ExtensionItem",
				route: "/extensions/0",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
			{
				moduleName: "ExtensionItem",
				route: "/extensions/1",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
			{
				moduleName: "ExtensionItem",
				route: "/extensions/2",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
			{
				moduleName: "ExtensionItem",
				route: "/extensions/3",
				icon: "list",
				flex: 6,
				bgImage: "bg_5.webp",
				subItems: [],
			},
		],
	}, */
	{
		moduleName: "LinkCollection",
		route: "/securitybundle",
		icon: "shield-alt",
		flex: 12,
		bgImage: "bg_3.webp",
		subItems: [],
	},
]
