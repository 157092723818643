import { accountApi, clientApi, customerApi, personApi, accountsApi, invitationApi } from "@/constants/accountApi"
import { itOpsAclApi, verjiAccountApi, verjiItOpsApi, verjiLinkOnboardingApi} from "@/constants/apiEndpoints"
import store, { Store } from "../store"
import axios, { AxiosInstance } from "axios"
import * as rax from "retry-axios"
import { ProvisioningFindRequest, CorrectInvitationRequest, FindPersonAccountRequest, CorrectOnboardingInvitationRequest } from "@/types/requests"
import { ProvisioningFindResponse, GetInvitesResponse, CorrectInvitationResponse, ClientInfoResponse, CreateTenantPayload, GetOnboardingInvitesResponse, CorrectOnboardingInvitationResponse } from "@/types/responses"
import { ClientInvitationResponse, ClientInvitationInfoResponse, ClientInvitationRegistrationsResponse, ClientInvitationRegistration } from "@/types/responses"
import { NavResponse, AclResponse, DomainsResponse, CompanyInfo, CustomerInfoResponse, PersonsResponse, CustomerClientsResponse } from "@/types/responses"
import { FindPersonAccountResponse, GetAccountDetails, LinkOnboardingUserInfo, LinkOnboardingUserInfoResponse, MemberInfo } from "@/types/responses"
import { linkRegistrationEnum, AnyDomain } from "@/types/enums"


enum HttpMethods {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
	DELETE = "DELETE"
}

enum ErrorCodes {
	TooManyRequests = "ERR_NETWORK"
}

export const defaultRetry = 2000;
export class ApiClient {
	//private baseUrl: Store["state"]["config"]["vmxAccountBaseUrl"]

    private verjiAccountUrl: Store["state"]["config"]["verjiAccountUrl"]
    private verjiAclUrl: Store["state"]["config"]["verjiAclUrl"]
    private verjiLinkOnboardingUrl: Store["state"]["config"]["verjiLinkOnboardingUrl"] 
    private verjiItOpsUrl: Store["state"]["config"]["verjiItopsUrl"]
    // private verjiSigningUrl: Store["state"]["config"]["verjiSigningUrl"]

	private accessToken: Store["state"]["oidcStore"]["access_token"]

	constructor() {
		this.accessToken = (store as Store).state.oidcStore.access_token

        //this.baseUrl = (store as Store).state.config.vmxAccountBaseUrl	
        
        this.verjiAccountUrl = (store as Store).state.config.verjiAccountUrl as string
        this.verjiAclUrl = (store as Store).state.config.verjiAclUrl as string

        this.verjiLinkOnboardingUrl = (store as Store).state.config.verjiLinkOnboardingUrl as string
        this.verjiItOpsUrl = (store as Store).state.config.verjiItopsUrl as string
        // this.verjiSigningUrl: = (store as Store).state.config.verjiSigningUrl as string
	}  

	private setupInterceptors(_axiosInstance: AxiosInstance){
		_axiosInstance.interceptors.response.use(async function (res) {
			return res;
		  }, 
		  async (res) => {
			if(res?.code != ErrorCodes.TooManyRequests && res?.code != 429 && res?.response?.status != 429){
				return Promise.reject(res);
			}

			console.info("Failed: " + res?.config?.url)
			await new Promise(r => setTimeout(r, res?.response?.headers?.RetryAfter ?? defaultRetry))
				.then(() => {
					console.info("Attempting retry: " + res.config.url)
					switch(res?.config?.method){
						case HttpMethods.GET:
							return this.get(res.config.url, res.config.data)
						case HttpMethods.POST:
							return this.post(res.config.url, res.config.data)
						case HttpMethods.PUT:
							return this.put(res.config.url, res.config.data)
						case HttpMethods.DELETE:
							return this.delete(res.config.url, res.config.data)
					}
				})
				.catch((_error) => {
					console.warn(_error)
					return Promise.reject(_error);
				});
			
		  });
	}

	private async post<TResponse>(endpoint: string, data?: unknown): Promise<TResponse> {
		// cannot use resolve, reject here because of the retry-axios interceptor
		const axiosInstance = axios.create({
			timeout: 300000,
		})
		// axiosInstance.defaults.baseURL = this.baseUrl as string
		axiosInstance.defaults.raxConfig = {
			retry: 0,
			noResponseRetries: 0,
			instance: axiosInstance,
			httpMethodsToRetry: ["POST"],
			statusCodesToRetry: [
				[100, 199],
				[401, 405, 429],
				[500, 599],
			],
			backoffType: "linear",
			onRetryAttempt: (err) => {
				return new Promise((resolve, reject) => {
					// eslint-disable-next-line
					const retryAttempt = rax.getConfig(err)!.currentRetryAttempt!
					console.log(`POST attempt #${retryAttempt}. Endpoint:$ "${endpoint}". Error: ${err.code}: ${err.message}`)
					try {
						resolve("testing")
						/*
						(store as Store).dispatch("oidcStore/authenticateOidcSilent").then((user) => {
							// eslint-disable-next-line
							console.log(`Silently renewed token for user: "${(user as any).profile.sub}". Retrying POST..`)
							resolve(`TRIED refreshing user!: ${JSON.stringify(user)}`)
						})
						*/
					} catch (e) {
						reject()
					}
				})
			},
		}
		axiosInstance.defaults.headers = {
			Authorization: "Bearer " + this.accessToken,
		}
		const interceptorId = rax.attach(axiosInstance)
		this.setupInterceptors(axiosInstance)
		const res = await axiosInstance.post<TResponse>(endpoint, data)
		return res.data
	}

	private async put<TResponse>(endpoint: string, data?: unknown): Promise<TResponse> {
		const axiosInstance = axios.create({
			timeout: 300000,
		})
		// axiosInstance.defaults.baseURL = this.baseUrl as string
		axiosInstance.defaults.raxConfig = {
			retry: 0,
			noResponseRetries: 0,
			instance: axiosInstance,
			httpMethodsToRetry: ["PUT"],
			statusCodesToRetry: [
				[100, 199],
				[401, 405, 429],
				[500, 599],
			],
			backoffType: "linear",
			onRetryAttempt: (err) => {
				return new Promise((resolve, reject) => {
					// eslint-disable-next-line
					const retryAttempt = rax.getConfig(err)!.currentRetryAttempt!
					console.log(`PUT attempt #${retryAttempt}. Endpoint:$ "${endpoint}". Error: ${err.code}: ${err.message}`)
					try {
						resolve("testing")
						/*
						(store as Store).dispatch("oidcStore/authenticateOidcSilent").then((user) => {
							// eslint-disable-next-line
							console.log(`Silently renewed token for user: "${(user as any).profile.sub}". Retrying POST..`)
							resolve(`TRIED refreshing user!: ${JSON.stringify(user)}`)
						})
						*/
					} catch (e) {
						reject()
					}
				})
			},
		}
		axiosInstance.defaults.headers = {
			Authorization: "Bearer " + this.accessToken,
		}
		const interceptorId = rax.attach(axiosInstance)
		this.setupInterceptors(axiosInstance)
		const res = await axiosInstance.put<TResponse>(endpoint, data)
		return res.data
	}

	private async delete<TResponse>(endpoint: string, data?: unknown): Promise<TResponse> {
		const axiosInstance = axios.create({
			timeout: 300000,
		})
		// axiosInstance.defaults.baseURL = this.baseUrl as string
		axiosInstance.defaults.raxConfig = {
			retry: 0,
			noResponseRetries: 0,
			instance: axiosInstance,
			httpMethodsToRetry: ["DELETE"],
			statusCodesToRetry: [
				[100, 199],
				[401, 405, 429],
				[500, 599],
			],
			backoffType: "linear",
			onRetryAttempt: (err) => {
				return new Promise((resolve, reject) => {
					// eslint-disable-next-line
					const retryAttempt = rax.getConfig(err)!.currentRetryAttempt!
					console.log(`DELETE attempt #${retryAttempt}. Endpoint:$ "${endpoint}". Error: ${err.code}: ${err.message}`)
					try {
						resolve("testing")
						/*
						(store as Store).dispatch("oidcStore/authenticateOidcSilent").then((user) => {
							// eslint-disable-next-line
							console.log(`Silently renewed token for user: "${(user as any).profile.sub}". Retrying POST..`)
							resolve(`TRIED refreshing user!: ${JSON.stringify(user)}`)
						})
						*/
					} catch (e) {
						reject()
					}
				})
			},
		}
		axiosInstance.defaults.headers = {
			Authorization: "Bearer " + this.accessToken,
		}
		const interceptorId = rax.attach(axiosInstance)
		this.setupInterceptors(axiosInstance)
		const res = await axiosInstance.delete<TResponse>(endpoint)
		return res.data
	}

	private async get<TResponse>(endpoint: string, timeout?: number): Promise<TResponse> {
		// cannot use resolve, reject here because of the retry-axios interceptor
		let defaultTimeout = 300000

		if (typeof timeout !== 'undefined') {
			defaultTimeout = timeout
		}
		const axiosInstance = axios.create({
			timeout: defaultTimeout,
		})
		// axiosInstance.defaults.baseURL = this.baseUrl as string
		axiosInstance.defaults.raxConfig = {
			retry: 0,
			noResponseRetries: 0,
			instance: axiosInstance,
			httpMethodsToRetry: ["GET"],
			statusCodesToRetry: [
				[100, 199],
				[401, 402], [404, 429],
				[500, 599],
			],
			backoffType: "linear",
			onRetryAttempt: (err) => {
				return new Promise((resolve, reject) => {
					// eslint-disable-next-line
					const retryAttempt = rax.getConfig(err)!.currentRetryAttempt!
					console.log(`GET attempt #${retryAttempt}.  Endpoint: "${endpoint}". Error: ${err.code}: ${err.message}`)
					try {
						resolve("testing")
						/*
						(store as Store).dispatch("oidcStore/authenticateOidcSilent").then((user) => {
							// eslint-disable-next-line
							console.log(`Silently renewed token for user: "${(user as any).profile.sub}". Retrying GET..`)
							resolve(`TRIED refreshing user!: ${JSON.stringify(user)}`)
						})
						*/
					} catch (e) {
						reject()
					}
				})
			},
		}
		axiosInstance.defaults.headers = {
			Authorization: "Bearer " + this.accessToken,
		}
		const interceptorId = rax.attach(axiosInstance)
		this.setupInterceptors(axiosInstance)
		const res = await axiosInstance.get<TResponse>(endpoint)
		return res.data
	}

	public async getNav(customerId: string|null|undefined): Promise<NavResponse> {
        return new Promise<NavResponse>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				let url = this.verjiAclUrl + itOpsAclApi.navigation.getNavigation
				url = url.replace("{tenantId}", customerId)
				const response = this.get<NavResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getModuleAcl(customerId: string|null|undefined, moduleName: string): Promise<AclResponse> {
		return new Promise<AclResponse>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				let url = this.verjiItOpsUrl + verjiItOpsApi.accessControl.getModule // accountApi.acl.module
				url = url.replace("{domainId}", customerId)
				url = url.replace("{moduleName}", moduleName)
				const response = this.get<AclResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

    public async getModuleAclAnyDomain(moduleName: string): Promise<AclResponse> {
        return new Promise<AclResponse>((resolve, reject) => {
			try {
				let url = this.verjiItOpsUrl + verjiItOpsApi.accessControl.getModule // accountApi.acl.module
				url = url.replace("{domainId}", AnyDomain.anyInstance)
				url = url.replace("{moduleName}", moduleName)
				const response = this.get<AclResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
    }

	public async getDomains(): Promise<DomainsResponse> {
		return new Promise<DomainsResponse>((resolve, reject) => {
			try {
				const url = this.verjiAclUrl + itOpsAclApi.accessControl.getDomains //accountApi.context.domains
				const response = this.get<DomainsResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getCustomerInfo(customerId: string|null|undefined): Promise<CustomerInfoResponse> {
		return new Promise<CustomerInfoResponse>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.getCustomer // accountApi.customer.customer
				url = url.replace("{customerId}", customerId)
				const response = this.get<CustomerInfoResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getClientInfo(customerId: string|null|undefined, clientId: string|null|undefined): Promise<ClientInfoResponse> {
		return new Promise<ClientInfoResponse>((resolve, reject) => {
			try {
				if (!customerId || !clientId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.getClient //accountApi.customer.client
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientId}", clientId)
				
				const response = this.get<ClientInfoResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async findProvisioningJobs(request: ProvisioningFindRequest): Promise<ProvisioningFindResponse> {
		return new Promise<ProvisioningFindResponse>((resolve, reject) => {
			try {
				const response = this.post<ProvisioningFindResponse>(this.verjiAccountUrl + verjiAccountApi.provisioning.findJobs, request)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async correctInvitation(customerId: string|null|undefined, jobId: string, request: CorrectInvitationRequest): Promise<CorrectInvitationResponse> {
		return new Promise<CorrectInvitationResponse>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.invite.correctInvitation;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{jobId}", jobId)
				const response = this.post<CorrectInvitationResponse>(url, request)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async correctOnboardingInvitation(tenantId: string|null|undefined, onboardingId: string, request: CorrectOnboardingInvitationRequest): Promise<CorrectOnboardingInvitationResponse> {
		return new Promise<CorrectOnboardingInvitationResponse>((resolve, reject) => {
			try {
				if (!tenantId)
					return reject()
				let url = this.verjiLinkOnboardingUrl + verjiAccountApi.invite.correctOnboardingInvitation;
				url = url.replace("{tenantId}", tenantId)
				url = url.replace("{onboardingId}", onboardingId)
				const response = this.post<CorrectOnboardingInvitationResponse>(url, request)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getInvites(customerId: string|null|undefined): Promise<GetInvitesResponse> {
		return new Promise<GetInvitesResponse>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.invite.getInvitations;
				url = url.replace("{customerId}", customerId)
				const response = this.get<GetInvitesResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getOnboardingInvites(tenantId: string|null|undefined): Promise<GetOnboardingInvitesResponse> {
		return new Promise<GetOnboardingInvitesResponse>((resolve, reject) => {
			try {
				if (!tenantId)
					return reject()
				let url = this.verjiLinkOnboardingUrl + verjiAccountApi.invite.getOnboardingInvitations;
				url = url.replace("{tenantId}", tenantId)
				const response = this.get<GetOnboardingInvitesResponse>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getPersons(customerId: string|null|undefined): Promise<PersonsResponse> {
		return new Promise<PersonsResponse>((resolve, reject) => {
			try {
				if (!customerId)
						return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.getPersons;
				//url = url.replace("{tenantId}", tenantId)
				url = url.replace("{customerId}", customerId)
				const response = this.get<PersonsResponse>(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getLinkOnboardingUsers(tenantId: string|null|undefined): Promise<LinkOnboardingUserInfo[]> {
		return new Promise<LinkOnboardingUserInfo[]>((resolve, reject) => {
			try {
				if (!tenantId)
						return reject()
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.getUsers; // invitationApi.fetchAssignableOwners
				url = url.replace("{customerId}", tenantId)
				const response = this.get<LinkOnboardingUserInfo[]>(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async resolveCustomerPersons(customerId: string|null|undefined, personIds: Array<string>): Promise<Array<MemberInfo>> {
		return new Promise<Array<MemberInfo>>((resolve, reject) => {
			try {
				if (!customerId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.resolvePersons;
				url = url.replace("{customerId}", customerId)
				const response = this.post<Array<MemberInfo>>(url, personIds)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async removeCustomerPerson(customerId: string|null|undefined, personId: string|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.removeCustomerPerson;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{personId}", personId)
				const response = this.delete(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async removeClientPerson(customerId: string|null|undefined, clientId: string|undefined, personId: string|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId|| !personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.removeClientPerson;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientId}", clientId)
				url = url.replace("{personId}", personId)
				const response = this.delete(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async updateClientName(customerId: string|null|undefined, clientId: string|undefined, info: CompanyInfo|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId || !info) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.updateClientName;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientId}", clientId)
				console.log(url)
				const response = this.put(url, {name: info.name})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updateClientDescription(customerId: string|null|undefined, clientId: string|undefined, info: CompanyInfo|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId || !info) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.updateClientDescription;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientId}", clientId)

				const response = this.put(url, {description: info.description})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updateCompanyName(customerId: string|null|undefined, info: CompanyInfo|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !info) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.updateCompanyName;
				url = url.replace("{customerId}", customerId)
				const response = this.put(url, {name: info.name})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updateCompanyDescription(customerId: string|null|undefined, info: CompanyInfo|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !info) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.updateCompanyDescription;
				url = url.replace("{customerId}", customerId)
				const response = this.put(url, {description: info.description})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updateCompanyBusinessRegistryId(customerId: string|null|undefined, info: CompanyInfo|null|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !info) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.updateCompanyBusinessRegistryId;
				url = url.replace("{customerId}", customerId)
				const response = this.put(url, {businessRegistryId: info.businessRegistryId})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updatePrimaryContact(customerId: string|null|undefined, personId: string|null): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId)
					return reject()
				//url = url.replace("{tenantId}", tenantId)

				if(personId){
					let url = this.verjiAccountUrl + verjiAccountApi.customer.updatePrimaryContact;
					url = url.replace("{customerId}", customerId)
					url = url.replace("{personId}", personId)
					const response = this.put(url)
					resolve(response)
				} else {
					let url = this.verjiAccountUrl + verjiAccountApi.customer.deletePrimaryContact;
					url = url.replace("{customerId}", customerId)
					const response = this.delete(url)
					resolve(response)
				}
			} catch (e) {
				reject(e)
			}
		})
	}

	public async updateClientPrimaryContact(customerId: string|null|undefined, clientId: string|null|undefined, personId: string|null): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId) return reject()
				//url = url.replace("{tenantId}", tenantId)

				if(personId){
					let url = this.verjiAccountUrl + verjiAccountApi.customer.updateClientPrimaryContact;
					url = url.replace("{customerId}", customerId)
					url = url.replace("{clientId}", clientId)
					url = url.replace("{personId}", personId)
					const response = this.put(url)
					resolve(response)
				} else {
					let url = this.verjiAccountUrl + verjiAccountApi.customer.deleteClientPrimaryContact;
					url = url.replace("{customerId}", customerId)
					url = url.replace("{clientId}", clientId)
					const response = this.delete(url)
					resolve(response)
				}
			} catch (e) {
				reject(e)
			}
		})
	}

	/*
		Get clients(Orgs, projects, cases, etc.) associated with customer(company)
	*/
	public async getCustomerClients(customerId: string|null|undefined): Promise<CustomerClientsResponse> {
		return new Promise<CustomerClientsResponse>((resolve, reject) => {
			try {
				if (!customerId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.customerClients;
				url = url.replace("{customerId}", customerId)
				const response: Promise<CustomerClientsResponse> = this.get(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getPersonClients(customerId: string|null|undefined, personId: string|null|undefined): Promise<CustomerClientsResponse> {
		return new Promise<CustomerClientsResponse>((resolve, reject) => {
			try {
				if (!customerId || !personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.personClients;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{personId}", personId)
				console.log("personClients: " + url)
				const response: Promise<CustomerClientsResponse> = this.get(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getClientPersons(customerId: string|null|undefined, clientId: string|null|undefined): Promise<PersonsResponse> {
		return new Promise<PersonsResponse>((resolve, reject) => {
			try {
				if (!customerId || !clientId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.persons
				url = url.replace("{clientId}", clientId)
				url = url.replace("{customerId}", customerId)
				const response: Promise<PersonsResponse> = this.get(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getClientInvitations(customerId: string|null|undefined): Promise<ClientInvitationResponse> {
		return new Promise<ClientInvitationResponse>((resolve, reject) => {
			try {
				if (!customerId) return reject()
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.activeInvitations;
				url = url.replace("{customerId}", customerId)
				console.log(url)
				const response: Promise<ClientInvitationResponse> = this.get(url)
				console.log(response)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getClientInvitationInfo(customerId: string|null|undefined, clientInvitationId: string | undefined): Promise<ClientInvitationInfoResponse> {
		return new Promise<ClientInvitationInfoResponse>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId) return reject()
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.invitation;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)
				const response: Promise<ClientInvitationInfoResponse> = this.get(url)
				console.log(response)
				resolve(response)
				
			} catch (e) {
				reject(e)
			}
		})
	}

	public async getClientInvitationRegistrations(customerId: string|null|undefined, clientInvitationId: string | undefined): Promise<ClientInvitationRegistrationsResponse> {
		return new Promise<ClientInvitationRegistrationsResponse>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId) return reject()
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.pendingRegistrations;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)

				const response: Promise<ClientInvitationRegistrationsResponse> = this.get(url)
				console.log(response)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async deleteRegistration(customerId: string|null|undefined, clientRegistrationId: string|undefined, registration: ClientInvitationRegistration): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientRegistrationId || !registration.jobId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.removeClientPerson;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientinvitationid}", clientRegistrationId)
				url = url.replace("{linkregistrationid}", registration.jobId)
				const response = this.delete(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async createNewClientInvitation(customerId: string|null|undefined, invitationName: string|null|undefined, invitingPersonId: string|null|undefined, manualApproval: boolean, onboardingKind: string|null|undefined): Promise<unknown> {
        return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !invitationName || !invitingPersonId || !onboardingKind){
					return reject()
				}

				const invitationType = linkRegistrationEnum.NewClientInvite
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.createInvitation;
				url = url.replace("{customerId}", customerId)
				
				const response = this.post(url, {
					customerId: customerId,
					displayName: invitationName,
					invitingUser: invitingPersonId,
					invitationType: invitationType,
					linkOnboardingKind: onboardingKind,
					manualApproval: manualApproval,
				})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async changeClientInvitationActiveStatus(customerId: string|null|undefined, clientInvitationId: string|undefined, active: boolean): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId){
					return reject()
				}

				if(active){
					// Implementation for activation
					return reject()
					// resolve()
				}

				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.deleteInvitation;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)

				const response = this.delete(url)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async changeClientInvitationManualApprovalStatus(customerId: string|null|undefined, clientInvitationId: string|undefined, manualApproval: boolean): Promise<unknown> {
        //this.baseUrl = (store as Store).state.config.verjiLinkOnboardingUrl as string
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId){
					return reject()
				}
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.setManualApproval;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)
				url = url.replace("{manualApproval}", manualApproval.toString())

				const response = this.put(url, {
					manualApproval: manualApproval
				})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async approveRegistration(customerId: string|null|undefined, clientInvitationId: string|undefined, linkRegistrationId: string|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId || !linkRegistrationId){
					return reject()
				}
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.approveRegistration;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)
				url = url.replace("{linkRegistrationId}", linkRegistrationId)

				const response = this.put(url, {
					manualConfirmation: false, // Old implementation
					manualApproval: false
				})

				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async rejectRegistration(customerId: string|null|undefined, clientInvitationId: string|undefined, linkRegistrationId: string|undefined): Promise<unknown> {
		return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientInvitationId || !linkRegistrationId){
					return reject()
				}
				let url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.rejectRegistration
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientInvitationId}", clientInvitationId)
				url = url.replace("{linkRegistrationId}", linkRegistrationId)

				const response = this.put(url, {
					manualConfirmation: false, // Old implementation
					manualApproval: false
				})

				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async reassignPersonToClient(customerId: string|null|undefined, clientId: string|null|undefined, personId: string|null|undefined, targetClientId: string|null|undefined, newClientName: string|null|undefined): Promise<unknown> {		
        return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId || !personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.reassignPerson;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{personId}", personId)

				console.log(url)
				const response = this.post(url, {targetClientId: targetClientId, newClientName: newClientName})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}

	public async reassignClientPerson(customerId: string|null|undefined, clientId: string|null|undefined, personId: string|null|undefined, targetOrganizationId: string): Promise<unknown> {

        return new Promise<unknown>((resolve, reject) => {
			try {
				if (!customerId || !clientId || !personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.customer.reassignClientPerson;
				url = url.replace("{customerId}", customerId)
				url = url.replace("{clientId}", clientId)
				url = url.replace("{personId}", personId)
				
				console.log(url)
				const response = this.post(url, {
					targetOrganizationId: targetOrganizationId,
				})
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}
	// MANAGE ACCOUNT

	public async findPersonAccount(request: FindPersonAccountRequest): Promise<FindPersonAccountResponse> {
		return new Promise<FindPersonAccountResponse>((resolve, reject) => {
			try {
                const url = this.verjiAccountUrl + verjiAccountApi.account.findPersonAccount;
				const response = this.post<FindPersonAccountResponse>(url, request)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async getAccountSummary(personId: string|null|undefined): Promise<GetAccountDetails> {
		return new Promise<GetAccountDetails>((resolve, reject) => {
			try {
				if (!personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.account.accountSummary;
				url = url.replace("{personId}", personId)
				const response = this.get<GetAccountDetails>(url, 150000)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

	public async removeAccount(personId: string|null|undefined): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			try {
				if (!personId) return reject()
				let url = this.verjiAccountUrl + verjiAccountApi.account.removeAccount;
				url = url.replace("{personId}", personId)
				const response = this.delete<void>(url)
				resolve(response)
			} catch (e) {
				console.warn(e)
				reject()
			}
		})
	}

    // TENANT ONBOARDING

    public async createTenantOnboarding(createTenantData: CreateTenantPayload): Promise<unknown> {
        return new Promise<unknown>((resolve, reject) => {
			try {
				if ( !createTenantData 
                    //|| !createTenantData.businessRegistryId no loger required
                    || !createTenantData.companyName 
                    || !createTenantData.email 
                    || !createTenantData.invitingPersonDisplayName 
                    || !createTenantData.invitingPersonId
                    || !createTenantData.phoneNumber
                    || !createTenantData.tenantId
                    || !createTenantData.tenantOnboardingId ){
					return reject()
				}

				const invitationType = linkRegistrationEnum.NewClientInvite
				const url = this.verjiLinkOnboardingUrl + verjiLinkOnboardingApi.linkOnboarding.createTenant;
				
				const response = this.post(url, createTenantData)
				resolve(response)
			} catch (e) {
				reject(e)
			}
		})
	}
}
