import Vue from "vue"
import Vuex from "vuex"
import { typedStorePlugin, Store as TypedStore } from "./combine"

Vue.use(Vuex)
Vue.use(typedStorePlugin)

// auto-register all vuex modules
const requireContext = require.context("./modules", false, /.*\.ts$/)
const modules = requireContext
	.keys()
	.map((file) => [file.replace(/(^.\/)|(\.ts$)/g, ""), requireContext(file)])
	.filter(mod => {
		return (mod[0] !== "oidcStore")
	})
	.reduce((modules, [name, module]) => {
	
		if (module.namespaced === undefined) {
			module.namespaced = true
		}

		return { ...modules, [name]: module }
	}, {})

export default new Vuex.Store({
	plugins: [],
	state: {},
	mutations: {},
	actions: {},
	modules: {
		...modules
	},
})

export type Store = TypedStore
