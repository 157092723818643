import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex"
import { ApiClient } from "@/api/apiClient"
import { CorrectInvitationRequest, CorrectOnboardingInvitationRequest } from "@/types/requests"
import { GetInvitesResponse, GetOnboardingInvitesResponse } from "./../../types/responses"
import { Namespaced, NamespacedState } from "@/types/functionTypes"
import store, { Store } from "@/store"

const defaultState = {
	invites: [],
	onboardingInvites: []
}

// state
type State = {
	invites: GetInvitesResponse["results"]
	onboardingInvites: GetOnboardingInvitesResponse["results"]
}

export const state: State = {...defaultState}

// mutations
enum MutationTypes {
	FLUSH_INVITES = "FLUSH_INVITES",
	UPDATE_INVITES = "UPDATE_INVITES",
	UPDATE_ONBOARDINGINVITES = "UPDATE_ONBOARDINGINVITES",
	RESET_STATE = "RESET_STATE"
	//setProvisioningFindResults = "resetProvisioningFindResults"
}

export type Mutations<S = State> = {
	[MutationTypes.UPDATE_INVITES](state: S, results: GetInvitesResponse["results"]): void
	[MutationTypes.UPDATE_ONBOARDINGINVITES](state: S, results: GetOnboardingInvitesResponse["results"]): void
	[MutationTypes.FLUSH_INVITES](state: S): void
	[MutationTypes.RESET_STATE](state: S): void
	//[MutationTypes.setProvisioningFindResults](state: S, results: ProvisioningFindResponse): void
}
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.UPDATE_INVITES](state: State, results: GetInvitesResponse["results"]) {
		state.invites = results
	},
	[MutationTypes.UPDATE_ONBOARDINGINVITES](state: State, results: GetOnboardingInvitesResponse["results"]) {
		state.onboardingInvites = results
	},
	[MutationTypes.FLUSH_INVITES](state: State) {
		state.invites = []
		state.onboardingInvites = []
	},
	[MutationTypes.RESET_STATE](state: State) {
		Object.assign(state, defaultState)
	},
}

// actions
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, "commit">
enum ActionTypes {
	updateInvites = "updateInvites",
	correctInvitation = "correctInvitation",
	correctOnboardingInvitation = "correctOnboardingInvitation",
	resetState = "resetState"
}
export interface Actions {
	[ActionTypes.updateInvites]({ commit }: AugmentedActionContext, payload: { allowOnboardingInvitesFlag?: boolean }): Promise<void>
	[ActionTypes.correctInvitation]({ commit }: AugmentedActionContext, payload: { jobId: string, request: CorrectInvitationRequest}): Promise<void>
	[ActionTypes.correctOnboardingInvitation]({ commit }: AugmentedActionContext, payload: { onboardingId: string, request: CorrectOnboardingInvitationRequest}): Promise<void>
	[ActionTypes.resetState]({ commit }: AugmentedActionContext): void
}

export const actions: ActionTree<State, State> & Actions = {
	async [ActionTypes.updateInvites]({ commit }, payload: { allowOnboardingInvitesFlag?: boolean }) {
		commit(MutationTypes.FLUSH_INVITES, undefined)
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id

			const allowOnboardingInvitesFlag = payload.allowOnboardingInvitesFlag ?? false;

			await Promise.all([
				client.getInvites(customerId), 
				allowOnboardingInvitesFlag ? client.getOnboardingInvites(customerId) : null
			]).then((allInvites) => {
				const invites = allInvites[0] as GetInvitesResponse;
				const onboardingInvites = allInvites[1];

				commit(MutationTypes.UPDATE_INVITES, invites.results)
				if (allowOnboardingInvitesFlag && onboardingInvites?.results && onboardingInvites?.results?.length > 0){
					commit(MutationTypes.UPDATE_ONBOARDINGINVITES, (onboardingInvites as GetOnboardingInvitesResponse).results)
				}
			});

			
		} catch (e) {
			commit(MutationTypes.UPDATE_INVITES, [])
			commit(MutationTypes.UPDATE_ONBOARDINGINVITES, [])
		}
	},
	async [ActionTypes.correctInvitation]({ commit }, payload: {jobId: string, request: CorrectInvitationRequest }) {
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			await client.correctInvitation(customerId, payload.jobId, payload.request)
		} catch (e) {
			console.error(e)
			throw `Could not correct the invitation. Error: ${JSON.stringify(e)} ` 
		}
	},
	async [ActionTypes.correctOnboardingInvitation]({ commit }, payload: {onboardingId: string, request: CorrectOnboardingInvitationRequest }) {
		try {
			const client = new ApiClient()
			const tenantId = (store as Store).state.context.selectedCustomer?.id
			await client.correctOnboardingInvitation(tenantId, payload.onboardingId, payload.request)
		} catch (e) {
			console.error(e)
			throw `Could not correct the onboarding invitation. Error: ${JSON.stringify(e)} ` 
		}
	},
	[ActionTypes.resetState]({ commit }) {
		commit(MutationTypes.RESET_STATE, undefined)
	},
}

// getters
export type Getters = {}
export const getters: GetterTree<State, State> & Getters = {}

export type ProvisioningMutations = Namespaced<Mutations, "provisioning">
export type ProvisioningActions = Namespaced<Actions, "provisioning">
export type ProvisioningGetters = Namespaced<Getters, "provisioning">
export type ProvisioningState = NamespacedState<State, "provisioning">
