
import store, { Store } from "@/store";
import { GrowthBook, Context } from "@growthbook/growthbook";

enum GrowthbookAttributes {
    UserId = "userid"
}
export enum GrowthbookFeatureFlags {
    ManageTenants = "manage-tenants",
    OnboardingInvites = "onboarding-invites" 
}

  /**
   * VerjiGrowthBook is a wrapper class which extends the default growthbook with a custom constructor with required params apihost and clientKey 
   */
  export class VerjiGrowthBook extends GrowthBook {
    private static instance: VerjiGrowthBook
    private hasUser: boolean
    /**
     * Constructs a VerjiGrowthBook 
     * @param apiHost The growthbook apiHost as a string
     * @param clientKey The growthbook clientKey as a string
     * @param userId  Optional param: The Verji userId logged in
     */
    constructor(apiHost: string, clientKey: string, userId?: string){
        const context: Context = {apiHost, clientKey}
        console.log("[verjiGrowthbook] - Creating...");
        super(context)

        if(userId){
            this.setAttributes({
                [GrowthbookAttributes.UserId]: userId,
            });
            this.hasUser = true
        } else {
            this.hasUser = false
        }
        console.log("[verjiGrowthbook] - Created: ", this);

        return this
    }

    public static getInstance(): VerjiGrowthBook {
        if ( !VerjiGrowthBook.instance) {
            let userId;
            let apiHost = "";
            let clientKey = "";
            try {
                userId = (store as Store).state.oidcStore.user?.profile?.sub ?? "" as string
                apiHost = (store as Store).state.config.growthbookConfig.apiHost ?? "" as string
                clientKey = (store as Store).state.config.growthbookConfig.clientKey ?? "" as string
            } catch (e) {
                console.error("[verjiGrowthbook] - Unable to get config: missing apiHost, clientKey or username.");
            }
            if(userId){
                console.log(`[verjiGrowthbook] - userId was provided: ${userId}.`)
                VerjiGrowthBook.instance = new VerjiGrowthBook(apiHost, clientKey, userId);
            }else {
                VerjiGrowthBook.instance = new VerjiGrowthBook(apiHost, clientKey);
            }
        } else {
            console.log( "[verjiGrowthbook] - Singleton is already instanciated. Using existing instance." );
        }
        
        return VerjiGrowthBook.instance;
    }

    public setGrowthBookUser(userId: string): void{
        console.log("[verjiGrowthbook] - Setting user...")
        this.setAttributes({
            [GrowthbookAttributes.UserId]: userId
        })
        console.log(`[verjiGrowthbook] - User was set to ${userId}.`)
        this.hasUser = true
    }

  }