import Vue from "vue"
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import App from "./App.vue"
//import "./registerServiceWorker"
//remove "register-service-worker": "^1.7.1"
import router from "./router"
import store, { Store } from "./store"
import "./assets/css/styles.scss"
import vuetify from "./plugins/vuetify"
import "@/plugins"
import "./components/global"
import { vuexOidcCreateStoreModule, VuexOidcErrorPayload, VuexOidcStoreListeners } from "vuex-oidc"
import { Config } from "@/types/config"
import { createLogger } from "vuex"
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
Vue.use(VueTelInputVuetify, { vuetify });
Vue.config.devtools = process.env.NODE_ENV === "development" ? true : false
Vue.config.productionTip = false
import VueI18n from "vue-i18n"
Vue.use(VueI18n)
import { loadLocaleMessages } from "./plugins/i18n"
import { VerjiGrowthBook } from "./utilities/growthbook";
export const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: loadLocaleMessages(),
})

fetch(process.env.BASE_URL + "config.json").then((response) => {
	response.json().then(async (config: Config) => {
		Vue.prototype.$config = config;
		(store as Store).dispatch("config/updateConfig", config);

		const oidcSettings = {
			authority: config.authority,
			clientId: config.clientId,
			redirectUri: config.redirectUri,
			responseType: config.responseType,
			scope: config.scope,
			silentRedirectUri: config.silentRedirectUri,
			automaticSilentRenew: config.automaticSilentRenew,
			automaticSilentSignin: config.automaticSilentSignin,
		}
		const oidcListeners: VuexOidcStoreListeners | undefined = config.loggerOidc
			? {
					userLoaded: (user) => {
						console.log("OIDC user is loaded", user);
                        VerjiGrowthBook.getInstance().setGrowthBookUser(user?.profile?.sub ?? "")
					},
					userUnloaded: () => console.log("OIDC user is unloaded"),
					accessTokenExpiring: () => console.log("Access token will expire"),
					accessTokenExpired: () => console.log("Access token did expire"),
					silentRenewError: () => console.log("OIDC user is unloaded - could not renew"),
					userSignedOut: () => console.log("OIDC user is signed out"),
					oidcError: (payload) => console.log(`An error occured at ${(payload as VuexOidcErrorPayload).context}:`, (payload as VuexOidcErrorPayload).error),
			}
			: {
				userLoaded: (user) => {
					console.log("OIDC user is loaded", user);
                    VerjiGrowthBook.getInstance().setGrowthBookUser(user?.profile?.sub ?? "")
				}
			}
		store.registerModule(
			"oidcStore",
			vuexOidcCreateStoreModule(
				oidcSettings,
				{
					namespaced: true,
					dispatchEventsOnWindow: true,
				},
				oidcListeners
			)
		)

		if (Vue.prototype.$config.loggerVuex) {
			const logger = createLogger({
				collapsed: false,
				logMutations: true,
				logActions: false,
			})
			logger(store)
		}

        // Create VerjiGrowthbook and load feature flags
        await VerjiGrowthBook.getInstance().loadFeatures({ autoRefresh: true }).catch((e: unknown) => {console.error("Something went wrong fetching the featureflag values...", e); return false});
        
		new Vue({
			router,
			store,
			i18n,
			vuetify,
			render: (h) => h(App),
		}).$mount("#app")
	})
})


