
//////////////////////////////////////
//        Verji ItOps Acl Api       //
// https://itopsacl.{env}.verji.app //
//////////////////////////////////////
const accessControl = {
    getDomains: "/api/v1.1/acl/tenants"
} 
const navigation = {
    getNavigation: "/api/v1.1/nav/domain/{tenantId}"
}
export const itOpsAclApi = {
    accessControl: accessControl,
    navigation: navigation
}
///////////////////////////////////////
//         Verji Account Api         //
// https://itopsacct.{env}.verji.app //
///////////////////////////////////////
const account ={
    findPersonAccount: "/api/v1.1/account/find", 
	accountSummary: "/api/v1.1/account/{personId}/summary",
	removeAccount: "/api/v1.1/account/{personId}"
}
const customer = {
    getCustomer: "/api/v1.1/customer/{customerId}",
    getClient: "/api/v1.1/customer/{customerId}/client/{clientId}",
    getPersons: "/api/v1.1/customer/{customerId}/persons",
    resolvePersons: "/api/v1.1/customer/{customerId}/members-info",
    removeCustomerPerson: "/api/v1.1/customer/{customerId}/person/{personId}",
    removeClientPerson: "/api/v1.1/customer/{customerId}/client/{clientId}/person/{personId}",
    updateClientName: "/api/v1.1/customer/{customerId}/client/{clientId}/name",
    updateClientDescription: "/api/v1.1/customer/{customerId}/client/{clientId}/description",
    updateCompanyName: "/api/v1.1/customer/{customerId}/name",
    updateCompanyDescription: "/api/v1.1/customer/{customerId}/description",
    updateCompanyBusinessRegistryId: "/api/v1.1/customer/{customerId}/business-registry-id",
    updatePrimaryContact: "/api/v1.1/customer/{customerId}/primary-contact/{personId}",
    deletePrimaryContact: "/api/v1.1/customer/{customerId}/primary-contact",
    updateClientPrimaryContact: "/api/v1.1/customer/{customerId}/client/{clientId}/primary-contact/{personId}",
    deleteClientPrimaryContact: "/api/v1.1/customer/{customerId}/client/{clientId}/primary-contact",
    customerClients: "/api/v1.1/customer/{customerId}/clients",
    personClients: "/api/v1.1/customer/{customerId}/clients/{personId}",
    persons: "/api/v1.1/customer/{customerId}/client/{clientId}/persons",
    reassignPerson: "/api/v1.1/customer/{customerId}/person/{personId}/reassign",
    reassignClientPerson: "/api/v1.1/customer/{customerId}/client/{clientId}/person/{personId}/reassign"
}

const provisioning = {
    findJobs: "/api/provision/v1.0/status/find"
}

const invite = {
    correctInvitation: "/api/v1.1/customer/{customerId}/invitation/correction/{jobId}",
    correctOnboardingInvitation: "/api/v1.1/tenant/{tenantId}/onboarding/{onboardingId}/resend",
    getInvitations: "/api/v1.1/customer/{customerId}/invitations",
	getOnboardingInvitations: "/api/v1.1/tenant/{tenantId}/onboarding"
}

export const verjiAccountApi = {
    account: account,
    customer: customer,
    provisioning: provisioning,
    invite: invite
}

///////////////////////////////////
//           Verji ItOps         //
// https://itops.{env}.verji.app //
///////////////////////////////////
export const acl = {
    getModule: "/api/v1.1/acl/tenant/{domainId}/permissions/{moduleName}"
}

export const verjiItOpsApi = {
    accessControl: acl
}

const linkOnboarding = {
    getUsers: "/api/v1.1/linkonboarding/tenant/{customerId}/users",
    activeInvitations: "/api/v1.1/customer/{customerId}/linkonboarding",
    invitation: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}",
    pendingRegistrations: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}/registration",
    createInvitation: "/api/v1.1/customer/{customerId}/linkonboarding",
    deleteInvitation: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}",
    setManualApproval: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}/manualapproval/{manualApproval}",
    approveRegistration: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}/registration/{linkRegistrationId}/approved",
    rejectRegistration: "/api/v1.1/customer/{customerId}/linkonboarding/{clientInvitationId}/registration/{linkRegistrationId}/rejected",
    createTenant: "/api/v1.1/tenant"
}

export const verjiLinkOnboardingApi = {
    linkOnboarding: linkOnboarding
}