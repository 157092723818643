import { NavActions, NavGetters, NavMutations, NavState } from "./modules/nav"
import { ConfigActions, ConfigGetters, ConfigMutations, ConfigState } from "./modules/config"
import { OidcActions, OidcGetters, OidcMutations, OidcState } from "./modules/oidcStore"
import { AclActions, AclGetters, AclMutations, AclState } from "./modules/acl"
import { ContextActions, ContextGetters, ContextMutations, ContextState } from "./modules/context"
import { ProvisioningActions, ProvisioningGetters, ProvisioningMutations, ProvisioningState } from "./modules/provisioning"


import Vue, { PluginObject } from "vue"
import { Store as VuexStore, CommitOptions, DispatchOptions } from "vuex"

type Actions = NavActions & ConfigActions & OidcActions & AclActions & ContextActions & ProvisioningActions
type Getters = NavGetters & ConfigGetters & OidcGetters & AclGetters & ContextGetters & ProvisioningGetters
type Mutations = NavMutations & ConfigMutations & OidcMutations & AclMutations & ContextMutations & ProvisioningMutations
export type State = NavState & ConfigState & OidcState & AclState & ContextState & ProvisioningState

export type Store = Omit<VuexStore<State>, "getters" | "commit" | "dispatch"> & {
	commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>
} & {
	// add Route to dispatch payload (used in our OIDC middleware)
	// add logout endSessionArgs (used in Logout.vue)
	dispatch<K extends keyof Actions>(key: K, payload?: Parameters<Actions[K]>[1] | unknown, options?: DispatchOptions): ReturnType<Actions[K]>
} & {
	getters: {
		[K in keyof Getters]: ReturnType<Getters[K]>
	}
}

declare module "vue/types/vue" {
	interface Vue {
		$vStore: Store
	}
}

export const typedStorePlugin: PluginObject<void> = {
	install(VueInstance: typeof Vue) {
		Object.defineProperty(VueInstance.prototype, "$vStore", {
			get() {
				return this.$store
			},
		})
	},
}