import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex"
import { Acl, AclResponse } from "@/types/responses"
import { Namespaced, NamespacedState } from "@/types/functionTypes"
import Vue from "vue"
import { dummyACL, dummyACLSub, dummyInvitation, dummyOrganization } from "@/constants/modules"
import store, { Store } from "@/store"
import { ApiClient } from "@/api/apiClient"

// state
type State = Acl
const defaultState = {}
export const state: State = { ...defaultState }

// mutations
enum MutationTypes {
	SET_ACL = "SET_ACL",
	FLUSH = "FLUSH",
	RESET_STATE = "RESET_STATE",
	RESET_MODULE = "RESET_MODULE"
}
export type Mutations<S = State> = {
	[MutationTypes.SET_ACL](state: S, acl: AclResponse): void
	[MutationTypes.RESET_STATE](state: S): void
	[MutationTypes.RESET_MODULE](state: S, moduleName: string): void
}
export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET_ACL](state: State, acl: AclResponse) {
		const ui = acl.ui.map((permission) => {
			return permission.name
		})
		const modules = acl.modules.map((module) => {
			return module.name
		})
		if (!state[acl.moduleName] || state[acl.moduleName].aclDomain !== acl.aclDomain) {
			const payload = {
				ui: ui,
				modules: modules,
				aclDomain: acl.aclDomain,
				hasPermission: acl.hasPermission,
			}
			Vue.set(state, acl.moduleName, payload)
		}
	},
	[MutationTypes.RESET_STATE](state: State, p?: undefined) {
		Object.keys(state).forEach((key) => {
			Vue.delete(state, key)
		})
	},
	[MutationTypes.RESET_MODULE](state: State, moduleName: string) {
		Vue.delete(state, moduleName)
	},
}

// actions
type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, "commit">
enum ActionTypes {
	updateACL = "updateACL",
	resetState = "resetState",
}
export interface Actions {
	[ActionTypes.updateACL]({ commit }: AugmentedActionContext, modulesName: string): Promise<void>
	[ActionTypes.resetState]({ commit }: AugmentedActionContext, p: undefined): void
}

export const actions: ActionTree<State, State> & Actions = {
	async [ActionTypes.updateACL]({ commit }, moduleName: string) {
		//get the ACL for the moduleName
		try {
			const client = new ApiClient()
			const customerId = (store as Store).state.context.selectedCustomer?.id
			const aclResponse: AclResponse = await client.getModuleAcl(customerId, moduleName)
			console.log("GETACL")
			console.log(aclResponse)
			if (aclResponse.hasPermission) commit(MutationTypes.SET_ACL, aclResponse)
		} catch (e) {
			console.warn(`Could not get ACL for module ${moduleName}. Error: ${JSON.stringify(e)}`)
			//dummy responses
			/*
			if (moduleName === "Customer") {
				if (dummyACL.hasPermission) commit(MutationTypes.SET_ACL, dummyACL)
			}
			if (moduleName === "ClientOrganization") {
				if (dummyACLSub.hasPermission) commit(MutationTypes.SET_ACL, dummyACLSub)
			}
			if (moduleName === "Invitation") {
				if (dummyInvitation.hasPermission) commit(MutationTypes.SET_ACL, dummyInvitation)
			}

			if (moduleName === "Organization") {
				if (dummyOrganization.hasPermission) commit(MutationTypes.SET_ACL, dummyOrganization)
			}
			*/
			commit(MutationTypes.RESET_MODULE, moduleName)

		}
	},
	[ActionTypes.resetState]({ commit }, p: undefined) {
		commit(MutationTypes.RESET_STATE, p)
	},
}

// getters
export type Getters = {
	getAcl(state: State): State
}
export const getters: GetterTree<State, State> & Getters = {
	getAcl: (state) => {
		return state
	},
}

export type AclMutations = Namespaced<Mutations, "acl">
export type AclActions = Namespaced<Actions, "acl">
export type AclGetters = Namespaced<Getters, "acl">
export type AclState = NamespacedState<State, "acl">
